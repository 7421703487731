import { useEffect, useRef, useState } from 'react'
import { FaBookOpen, FaSearch } from 'react-icons/fa'
import { Card } from '../../components/Card'
import { NewOldFilters } from '../../components/NewOldFilters'
import { Title } from '../../components/Title'
import { UserName } from '../../components/UserName'
import { HiOutlineUpload } from 'react-icons/hi'
import { List } from '../../components/List'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { server } from '../../utils/connection'
import { alert } from '../../utils/alert';
import { Modal } from '../../components/Modal'
import { CreateInvoice } from '../../components/CreateInvoice'
import { EditPolicie } from '../../components/EditPolicie'
import userData from '../../utils/getUserData'

export const Cobranza = () => {
  const [poliza, setPoliza] = useState([]);
  const [saved, setSaved] = useState([])
  const [invoices, setInvoices] = useState([]);
  const [current, setCurrent] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<boolean>(false)
  const [error, setError] = useState([{ name: 'policies', state: false }, { name: 'recipe', state: false }]);
  const [editedPolicie, setEditedPolicie] = useState<any>([])

  const cardCollection = useRef<HTMLHeadingElement>(null);

  const getData = (data: any) => {
    if (data.length > 0) {
      return setPoliza(data)
    }
    return setPoliza([])
  }
  /* eslint-disable */
  const getPolicies = async () => {
    setLoading(true)
    try {
      let request = await server(true).post(`/pdf-bot/get-all-policies`, { "getHistory": false })
      if (!request.data.policies) {
        setLoading(false)
        setSaved([])
        return setPoliza([])
      }
      setPoliza(request.data.policies)
      setSaved(request.data.policies)
      setLoading(false)
    } catch (error) {
      // eslint-disable-next-line
      setError(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.name === 'policies') {
            return {
              ...item,/* 
              ['state']: true */
            };
          }
          return item;
        });
        return updatedItems;
      });
      alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
      setLoading(false)
    }
  }
  /* eslint-enable */
  const getRecipe = async () => {
    try {
      let response = await server(true).post(`/payment/invoice/get`, {});
      let newData = []
      let data = response.data.invoices
      data.forEach((e) => {
        let validate = newData.find(c => c.name && c.name === e.policyNumber);
        if (!validate) {
          let newElement = { 'name': e.policyNumber }
          newData.push(newElement)
        }
      })
      setInvoices(newData)
    } catch (error) {
      setError(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.name === 'recipe') {
            return {
              ...item,
              /* ['state']: true */
            };
          }
          return item;
        });
        return updatedItems;
      });
      /*  alert('No hay facturas.', 'error', '#c35353', 2000) */
    }
  }

  const handleOnSelect = (item: any) => {
    return setCurrent(item)
  }

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  const reloadPolicies = async () => {
    getPolicies()
  }

  const validateError = (name: string) => {
    return error.some((e) => e.name === name && e.state === false)
  }

  const editPolicie = (e) => {
    let policie = poliza.filter((element) => element.policyNumber === e)
    setEditedPolicie(policie)
    setEdit(true)
  }

  useEffect(() => {
    getPolicies()
    getRecipe()
  }, [])
  return (
    <div className='flex flex-col justify-center py-10'>
      <div className='lg:flex lg:flex-row w-10/12 self-center'>
        <UserName />
      </div>
      <div className='lg:flex lg:flex-row w-10/12 self-center'>
        <Title Icon={FaSearch} title="CONSULTA DE PÓLIZAS" />
      </div>
      <div className='flex flex-col gris1 w-10/12 self-center'>
        <div className='flex flex-wrap w-full p-4'>
          <NewOldFilters getData={getData} justify="justify-start" newData={poliza && poliza.length > 0 ? poliza : []} save={saved} sendData={true} reference={cardCollection} fieldStart={"vigenciaInicio"} fieldEnd={"vigenciaFin"} newVersion={true} />
        </div>
        <div className='flex flex-wrap lg:w-full sm:w-full md:w-full place-items-center p-5' ref={cardCollection}>
          {
            loading ? <div className='flex text-white w-full justify-center'>
              <div className='blue1 mb-10 mt-10 p-5'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
            </div> : ''
          }
          {
            validateError('policies') ? '' : <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>Hubo un error al traer las polizas.</h2></div>
          }
          {poliza && poliza.length > 0 ? poliza.map((e) => (
            <div className='px-2 py-5'>
              <Card
                owner={e.userData.name}
                concepto={e.concept}
                ramo={e.type}
                compania={e.company}
                poliza={e.policyNumber}
                vigenciaInicio={e.dateFrom}
                vigenciaFin={e.dateUntil}
                prima={e.totalPremium}
                tipoPrima={e.premiumType}
                pdf={e.fileData}
                edit={editPolicie}
              ></Card>
            </div>
          )) : ''
          }
          {
            poliza.length === 0 && !loading && validateError('policies') ? <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No se encontraron polizas.</h2></div> : ''
          }
        </div>
        {/* <Paginator data={[]} getData={getData} /> */}
      </div>
      <div className='lg:flex lg:flex-row w-full lg:w-9/12 self-center mt-10'>
        <div className='gris1 lg:w-6/12 w-full h-full'>
          <Title Icon={FaBookOpen} length="lg:w-full" title="HISTORIAL DE FACTURAS" />
          <div className={`flex flex-row justify-center items-center w-full py-6 px-5`} >
            <div className='flex flex-row w-40'>
              <p className={`text-black text-sm px-2 font-bold`}>Referente a póliza:</p>
            </div>
            <div className='lg:w-6/12 w-full lg:mt-0 mt-5'>
              <ReactSearchAutocomplete
                items={invoices}
                formatResult={formatResult}
                onSelect={handleOnSelect}
                styling={{ borderRadius: "0px" }}
              />
            </div>
          </div>
        </div>
        <div className='w-10'></div>
        <div className='morado2 lg:w-6/12 w-full h-full'>
          <div className={"flex flex-row lg:w-auto items-center morado3bg px-5 sm:w-full justify-center"}>
            <HiOutlineUpload className="text-white text-xl mr-3" /><h1 className={'text-white font-bold py-2'}>SUBIR FACTURA</h1>
          </div>
          {userData().roles[0] === "ROLE_SUPER_ADMIN" || userData().roles[0] === "ROLE_ADMIN" ? <div className='lg:flex lg:flex-row sm:flex-col sm:justify-center py-6 px-5'>
            <button type='submit' className='naranja1 text-white w-full lg:w-auto px-4 py-2 h-11 rounded-md font-bold' onClick={() => { show ? setShow(false) : setShow(true) }} >SUBIR FACTURA</button>
          </div> : ''
          }
        </div>
      </div>
      <List currentData={current} />
      <Modal isVisible={show} onClose={() => { setShow(false) }}>
        <CreateInvoice />
      </Modal>
      <Modal isVisible={edit} onClose={() => { setEdit(false) }} size="[800px]" >
        <EditPolicie current={current} policie={editedPolicie} reload={reloadPolicies} />
      </Modal>
    </div>
  )
}


