import { useEffect, useRef, useState } from 'react'
import { server } from '../utils/connection';
import { Formik, Form, Field } from 'formik';
import { alert } from '../utils/alert';
import { SubmitButton } from './SubmitButton';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { validateModal } from '../utils/validateModal';

export const ConfigurationAdmin = () => {
    const [status] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState<any>({});
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [permissionsForm] = useState({});
    /* const [confirmation, setConfirmation] = useState(false) */

    const ref = useRef(null);

    const handleOnSelect = (employee: any) => {
        setEmployee(employee)
    }

    const getRoles = async () => {
        try {
            let request = await server(true).get('users/rbac/roles')
            setRoles(request.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getEmployees = async () => {
        try {
            let request = await server(true).get(`/users/employees`)
            setEmployees(request.data.userList)
        } catch (error) {
            console.log(error)
        }
    }

    const formatResult = (item: any) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const updateConfirmation = (state: any) => {
        /* setConfirmation(state) */
    }

    const savePermissions = (type: string) => {
        setRole(type)
        switch (type) {
            case "1":
                setPermissions(roles[0].permissions)
                break;
            case "2":
                setPermissions(roles[1].permissions)
                break;
            case "3":
                setPermissions(roles[2].permissions)
                break;
            case "4":
                setPermissions(roles[3].permissions)
                break;
            case "5":
                setPermissions(roles[4].permissions)
                break;
            default:
                break;
        }
    }

    const validatePermission = (permissionType: string) => {
        return permissions.some((e) => e.permission === permissionType)
    }

    useEffect(() => {
        getRoles()
        getEmployees()
    }, [])

    return (
        <div className='lg:flex flex-col w-full justify-center items-center p-8'>
            <div className='flex flex-row space-x-3'>
                <p className='py-2 gris2'>Tipo de colaborador:</p>
                <select className='py-2 px-3 border-none' onChange={(e) => savePermissions(e.target.value)}>
                    <option value={0}>Elige una opción</option>
                    {
                        roles && roles.length > 0 ? roles.map((e) => (
                            <option value={e.id}> {e.displayName} </option>
                        )) : ''
                    }
                </select>
            </div>
            <div className='flex flex-col lg:w-8/12 sm:w-full mt-5'>
                <p className='gris2 '>Buscar colaborador:</p>
                <ReactSearchAutocomplete
                    items={employees}
                    formatResult={formatResult}
                    onSelect={handleOnSelect}
                    styling={{ borderRadius: "0px" }}
                />
            </div>
            {permissions.length > 0 ? <Formik
                initialValues={permissionsForm}
                onSubmit={async (values) => {
                    if (Object.keys(employee).length === 0) {
                        return alert('Porfavor selecciona un colaborador primero.', 'error', '#c35353', 2000)
                    }
                    let idCurrentRole = roles.filter((e) => e.role === employee.roles[0]) 
                    validateModal(updateConfirmation, role, employee, idCurrentRole)
                }}
                innerRef={ref}>
                <Form className="w-8/12">
                    <div className="w-full mt-10 ">
                        <table className='w-full '>
                            <thead className='w-full'>
                                <tr>
                                    <th></th>
                                    <th>Visualizar</th>
                                    <th>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='gris2'>CLIENTES</td>
                                    <td><p className='text-center'><Field type="checkbox" name="VIEW_CLIENTES" className={`${validatePermission('VIEW_CLIENTES') ? 'circle-check' : 'circle'}`} checked={validatePermission('VIEW_CLIENTES')} disabled /></p></td>
                                    <td><p className='text-center'><Field type="checkbox" name="EDIT_CLIENTES" className={`${validatePermission('EDIT_CLIENTES') ? 'circle-check' : 'circle'}`} checked={validatePermission('EDIT_CLIENTES')} disabled/></p></td>
                                </tr>
                                <tr>
                                    <td className='gris2'>COBRANZA</td>
                                    <td><p className='text-center'><Field type="checkbox" name="VIEW_COBRANZA" className={`${validatePermission('VIEW_COBRANZA') ? 'circle-check' : 'circle'}`} checked={validatePermission('VIEW_CLIENTES')} disabled/></p></td>
                                    <td><p className='text-center'><Field type="checkbox" name="EDIT_COBRANZA" className={`${validatePermission('EDIT_COBRANZA') ? 'circle-check' : 'circle'}`} checked={validatePermission('EDIT_COBRANZA')} disabled/></p></td>
                                </tr>
                                <tr>
                                    <td className='gris2'>REPORTES</td>
                                    <td><p className='text-center'><Field type="checkbox" name="VIEW_STATISTICS" className={`${validatePermission('VIEW_STATISTICS') ? 'circle-check' : 'circle'}`} checked={validatePermission('VIEW_STATISTICS')} disabled/></p></td>
                                    <td><p className='text-center'><Field type="checkbox" name="EDIT_STATISTICS" className={`${validatePermission('EDIT_STATISTICS') ? 'circle-check' : 'circle'}`} checked={validatePermission('EDIT_STATISTICS')} disabled/></p></td>
                                </tr>
                                <tr>
                                    <td className='gris2'>ADMN/CONFIG</td>
                                    <td><p className='text-center'><Field type="checkbox" name="VIEW_CONFIG" className={`${validatePermission('VIEW_CONFIG') ? 'circle-check' : 'circle'}`} checked={validatePermission('VIEW_CONFIG')} disabled/></p></td>
                                    <td><p className='text-center'><Field type="checkbox" name="EDIT_CONFIG" className={`${validatePermission('EDIT_CONFIG') ? 'circle-check' : 'circle'}`} checked={validatePermission('EDIT_CONFIG')} disabled/></p></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='flex w-full justify-end mt-5'>
                            <div className='w-3/12'>
                                <SubmitButton status={status} text={'Guardar'} />
                            </div>
                        </div>
                    </div>
                </Form >
            </Formik > : ''}
        </div >
    )
}
