import { Home } from '../pages/Home';
import { Polizas } from '../pages/Polizas';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Siniestros } from '../pages/Siniestros';
import { Pagos } from '../pages/Pagos';
import { Datos } from '../pages/Datos';
import { DatosEjecutivo } from '../pages/ejecutivo/DatosEjecutivo';
import { Poliza } from '../pages/ejecutivo/Poliza';
import { Cobranza } from '../pages/ejecutivo/Cobranza';
import { Reportes } from '../pages/ejecutivo/Reportes';
import { Contacto } from '../pages/Contacto';
import { TerminosCondiciones } from '../pages/TerminosCondiciones';
import { Privacidad } from '../pages/Privacidad';
import { Login } from '../components/Login';
import { FaCarSide, FaBuilding, FaHeartbeat, FaHouseDamage, FaUsers } from "react-icons/fa";
import userToken from './getUserToken';

const validatePath = (name: string) => {
  switch (window.location.pathname) {
    case name:
      return true
    default:
      return false
  }
}

const navigationDataUser = [
  { name: 'Pagos', href: 'pagos', Component: Pagos },
  { name: 'home', href: 'home', Component: Home },
  { name: 'Cobranza', href: 'poliza', Component: Polizas },
  { name: 'Reportes', href: 'siniestros', Component: Siniestros },
  { name: 'Login', href: 'login', Component: Login, Icon: ArrowRightOnRectangleIcon },
  { name: 'Mis Datos', href: 'datos', Component: Datos },
  { name: 'Ayuda/Contacto', href: 'contacto', Component: Contacto },
  { name: 'Cerrar Sesión', href: 'logout', Component: Login, Icon: ArrowRightOnRectangleIcon },
  { name: 'terminos-&-condiciones', href: 'terminos-y-condiciones', Component: TerminosCondiciones },
  { name: 'privacidad', href: 'privacidad', Component: Privacidad },
]

const navigationDataAdmin = [
  { name: 'poliza', href: 'poliza', Component: Poliza },
  { name: 'ejecutivo/cobranza', href: 'cobranza', Component: Cobranza },
  { name: 'ejecutivo/Reportes', href: 'reportes', Component: Reportes },
  { name: 'ejecutivo/Datos', href: 'datos', Component: DatosEjecutivo },
  { name: 'terminos-&-condiciones', href: 'terminos-y-condiciones', Component: TerminosCondiciones },
  { name: 'privacidad', href: 'privacidad', Component: Privacidad },
  { name: 'Login', href: 'login', Component: Login, Icon: ArrowRightOnRectangleIcon },
  { name: 'home', href: 'home', Component: Home },
  { name: 'Cerrar Sesión', href: 'logout', Component: Login, Icon: ArrowRightOnRectangleIcon },
]

const navigationDataLogOut = [
  { name: 'home', href: 'home', Component: Home },
  /* { name: 'Login', href: 'login', Component: Login, Icon: ArrowRightOnRectangleIcon }, */
  { name: 'Ayuda/Contacto', href: 'contacto', Component: Contacto },
  { name: 'terminos-y-condiciones', href: 'terminos-y-condiciones', Component: TerminosCondiciones },
  { name: 'privacidad', href: 'privacidad', Component: Privacidad },
]

const navigationLogOut = [
  { name: 'Inicio', href: 'home', active: validatePath("/home"), place: "test", show: true },
  { name: 'Seguros', href: 'home', active: validatePath("/seguros"), place: "test1", show: true },
  { name: 'Acerca de nosotros', href: 'home', active: validatePath("/nosotros"), place: "test2", show: true },
  { name: 'Contáctanos', href: 'home', active: validatePath("/contacto"), place: "test3", show: true },
  /* { name: 'Login', href: 'login', active: validatePath("/login"), show:true }, */
]

const navigationLogOutMobile = [
  { name: 'Inicio', href: 'home', active: validatePath("/home"), place: "test,", show: true },
  { name: 'Seguros', href: 'home', active: validatePath("/seguros"), place: "test1", show: true },
  { name: 'Acerca de nosotros', href: 'home', active: validatePath("/nosotros"), place: "test2", show: true },
  { name: 'Contáctanos', href: 'home', active: validatePath("/contacto"), place: "test3", show: true },
  /* { name: 'Login', href: 'login', active: validatePath("/login"), show:true}, */
]

const navigationUser = [
  { name: 'Mis Pólizas', href: 'poliza', Component: Polizas, active: validatePath("/poliza"), show: true },
  { name: 'Mis Siniestros', href: 'siniestros', Component: Siniestros, active: validatePath("/siniestros"), show: true },
  { name: 'Mis Pagos', href: 'pagos', Component: Pagos, active: validatePath("/pagos"), show: true },
  { name: 'Mis Datos', href: 'datos', Component: Datos, active: validatePath("/datos"), show: true },
  { name: 'Ayuda/Contacto', href: 'contacto', Component: Contacto, active: validatePath("/contacto"), show: true },
  { name: 'Cerrar Sesión', href: 'logout', Component: Login, Icon: ArrowRightOnRectangleIcon, active: validatePath("/logout"), show: true },
]

const navigationAdmin = [
  { name: 'Clientes/Pólizas', href: 'poliza', Component: Poliza, active: validatePath("poliza"), show: true },
  { name: 'Cobranza', href: 'cobranza', Component: Cobranza, active: validatePath("cobranza"), show: true },
  { name: 'Reportes', href: 'reportes', Component: Reportes, active: validatePath("reportes"), show: true },
  { name: 'Administración/Configuración', href: 'datos', Component: DatosEjecutivo, active: validatePath("Datos"), show: true },
  { name: 'Cerrar Sesión', href: 'logout', Component: Login, Icon: ArrowRightOnRectangleIcon, active: validatePath("/logout"), show: true },
]

const headerAuth = {
  "Content-Type": `application/json`,
  "Accept": "*/*",
  "Authorization": `Bearer ${userToken()}`
}

const headers = {
  "Content-Type": `application/json`,
  "Accept": "*/*",
}

const cards = [
  {
    title: "Autos",
    description: <p className="font-medium py-1">Tenemos la protección a la <br /> medida de tu vehículo <br />contra cualquier accidente.</p>,
    info: '/',
    Icon: FaCarSide
  },
  {
    title: "Vida",
    description: <p className="font-medium py-1">Protegemos a tu familia,<br /> cuidamos tu salud y<br /> buscamos tu bienestar.</p>,
    info: '/',
    Icon: FaUsers
  },
  {
    title: "Gastos Médicos",
    description: <p className="font-medium py-1">Te ayudamos para proteger<br /> y recuperar tu salud<br /> y la de tu familia.</p>,
    info: '/',
    Icon: FaHeartbeat
  },
  {
    title: "Hogar",
    description: <p className="font-medium py-1">Trabajamos para proteger<br /> tu patrimonio ante<br /> cualquier eventualidad.</p>,
    info: '/',
    Icon: FaHouseDamage
  },
  {
    title: "Empresas",
    description: <p className="font-medium py-1">Te apoyaremos para<br /> blindar y resolver<br /> posibles siniestros.</p>,
    info: '/',
    Icon: FaBuilding
  },
]

const dataCard = [
  {
    title: <p className="py-5 text-center text-lg">Tuviste un accidente<br /> regresando a casa</p>,
    image: "./Rectangle1.png",
    Icon: FaCarSide,
    dato1: "Daños",
    dato2: "Tu pagas",
    value1: "$150,000",
    value2: "5% del valor de tu auto ($12,500)"
  },
  {
    title: <p className="py-5 text-center text-lg">Inversión con protección<br /> para ti y los tuyos</p>,
    image: "./hand.png",
    Icon: FaBuilding,
    dato1: "Inversión",
    dato2: "15 años o fallecimiento",
    value1: "$5,000 mensuales",
    value2: <>$866,169 <br /> para ti</>
  },
  {
    title: <p className="py-5 text-center text-lg">Jugando fútbol te<br /> rompes la rodilla!</p>,
    image: "./Rectangle3.png",
    Icon: FaHeartbeat,
    dato1: <>Costo operación<br /> y recuperación</>,
    dato2: "Tu solo pagas",
    value1: "$200,000",
    value2: "$34,400"
  },
  {
    title: <p className="py-5 text-center text-lg">Unos ladrones se roban<br /> bienes de tu casa</p>,
    image: "./Rectangle4.png",
    Icon: FaHouseDamage,
    dato1: "Valor de bienes",
    dato2: "Te regresan",
    value1: "$50,000",
    value2: "85% del valor"
  }
]

export {
  dataCard,
  cards,
  navigationDataUser,
  navigationDataAdmin,
  navigationDataLogOut,
  navigationLogOut,
  navigationUser,
  navigationAdmin,
  headerAuth,
  headers,
  navigationLogOutMobile,
}
