import { PersonalData } from '../components/PersonalData'
import { Title } from '../components/Title'
import { UserName } from '../components/UserName'

export const Datos = () => {


    return (
        <div className='flex flex-col justify-center py-10'>

            <div className='lg:flex lg:flex-row w-8/12 self-center'>
                <UserName />
            </div>

            <div className='lg:flex lg:flex-row lg:w-8/12 flex-col w-full self-center'>
                <div className=' lg:w-6/12 w-full '>
                    <div className='lg:w-6/12'>
                        <Title title="MIS DATOS" />
                    </div>
                    <div className='gris1'>
                        <PersonalData />
                    </div>
                </div>
                {/* <div className=' lg:w-6/12 w-full pb-10'>
                    <div className='lg:w-6/12'>
                        <Title title="CONFIGURACIÓN" />
                    </div>
                    <div className='gris3'>
                        <Configuration />
                    </div>
                </div> */}
            </div>
        </div>
    )
}
