import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { server } from '../utils/connection';
import { useEffect } from 'react';
import { useState } from 'react';
import { alert } from '../utils/alert';

export const SearchClient = ({ search }: any) => {
  const [data, setData] = useState<any>()
  const [type, setType] = useState<any>("cliente")

  const handleOnSelect = (item: any) => {
    let prevUsersRaw = localStorage.getItem('users')
    if (prevUsersRaw && JSON.parse(prevUsersRaw).length >= 6) {
      return alert('Maximo de pestañas alcanzado, cierra algunas.', 'error', '#c35353', 2000)
    }
    let prevUsers = JSON.parse(prevUsersRaw)
    if(prevUsers){
      let validateDuplicates = prevUsers.some((e) => e.id === item.id)
      if(validateDuplicates){
        return alert('ya esta abierto este usuario.', 'error', '#c35353', 2000)
      }
    }
    if (prevUsersRaw) {
      localStorage.setItem('users', JSON.stringify([...[item], ...prevUsers]))
      return search()
    }
    localStorage.setItem('users', JSON.stringify([item]))
    return search()
  }

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{type === 'poliza' ? item.policyNumber : item.name}</span>
      </>
    )
  }

  const getPolices = async () => {
    try {
      let response = await server(true).post(`/pdf-bot/get-all-policies`, {
        "getHistory": false
      });
      let newData = []
      let data = response.data.policies
      data.forEach((e) => {
        e.userData['policyNumber'] = e.policyNumber
        newData.push(e.userData)
      })
      setData(newData)
    } catch (error) {
      alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
    }
  }

  const getData = async () => {
    let request = await server(true).get('/users')
    setData(request.data.userList)
  }

  useEffect(() => {
    if (type === 'poliza') {
      getPolices()
    } else {
      getData()
    }
  }, [type])

  return (
      <div className='lg:flex lg:flex-row sm:flex-col sm:justify-center py-10 px-5'>
        <div className='flex lg:w-6/12 sm:w-full lg:mr-2'>
          <select className='h-11 w-10/12 px-3 border-none' onChange={(e) => setType(e.target.value)}>
            <option value="cliente">Cliente</option>
            <option value="poliza">Poliza</option>
          </select>
        </div>
        <div className='flex flex-col lg:w-8/12 sm:w-full lg:mt-0 mt-5'>
          <ReactSearchAutocomplete
            items={data}
            formatResult={formatResult}
            onSelect={handleOnSelect}
            fuseOptions={type === 'poliza' ? { keys: ["policyNumber"] } : {}}
            styling={{ borderRadius: "0px" }}
          />
        </div>
      </div>
  )
}