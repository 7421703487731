import { useField } from 'formik'

interface Props {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password' | 'checkbox';
    placeholder?: string;
    [x: string]: any;
    class?: string;
    data: any;
    valueOpt: string;
    level1?: string;
    displayName: string;
    selected?: any;
    disabled?: boolean
}

const classes = {
    default: "border-none gris1 w-60"
}

export default function MySelectInput({ label, ...props }: Props) {

    const [field, meta] = useField(props)

    return (
        <>
            <div className='grid grid-col-1'>
                <label htmlFor={props.id || props.name}> {label} </label>
                {props.data.length > 0 ? <select className={props.class ? props.class : classes.default} {...field} {...props} onChangeCapture={(e) => {props.selected ? props.selected(e) : e = null}} disabled={props.disabled}>
                    <option>Elige una empresa...</option>
                    {
                        props.level1 ? props.data.map((e, i) => (

                            <option value={e[props.level1][props.valueOpt]}> {e[props.level1][props.displayName]} </option>

                        )) : props.data.map((e, i) => (

                            <option value={e[props.valueOpt]}> {e[props.displayName]} </option>

                        ))
                    }
                </select> : <select className={props.class ? props.class : classes.default} disabled={props.disabled}><option>Elige una empresa...</option></select>}
                {
                    meta.touched && meta.error && (
                        <div>
                            <span className="text-red-600 font-bold"> {meta.error} </span>
                        </div>
                    )
                }
            </div>
        </>
    )
}
