export function extractNumberFromString(str) {
    const numberRegex = /[0-9]+(\.[0-9]+)?/;
    const numberMatch = str.match(numberRegex);

    if (numberMatch) {
        const extractedNumber = parseFloat(numberMatch[0]);
        return extractedNumber;
    }

    return null; // or any other value you prefer for non-matching cases
}