
export const Footer = () => {
  return (
    <div className="gris5bg">
      <div className='flex lg:flex-row flex-col py-10 px-4 sm:px-6 lg:px-8 justify-center'>

        <div className='flex flex-col lg:w-9/12 w-full lg:mb-0 mb-3 lg:items-start items-center'>
          <div className="lg:self-start">
            <h1 className="font-bold gris7 text-lg">CONTACTO</h1>
          </div>
          <ul>
            <li className="gris8 break-words">Dirección: Joaquín Arrieta 134, Col. Arcos Sur <br /> CP: 44130 <br />Guadalajara, jal.</li>
          </ul>
        </div>

        <div className='lg:w-2/12 w-full mb-3 flex flex-col lg:items-end items-center'>
          <div className="lg:self-end">
            <h1 className="font-bold gris7 text-lg">NOSOTROS</h1>
          </div>
          <ul className="lg:text-right">
            <a href="/terminos-y-condiciones"><li className="gris8">Términos de servicio</li></a>
            <a href="/privacidad"><li className="gris8">Políticas de privacidad</li></a>
          </ul>
        </div>
      </div>
      <div className="flex flex-row justify-center mb-7">
        <p className="gris8">Copyright © 2022 <span className="font-bold text-lg gris7"> AC SEGUROS </span></p>
      </div>
    </div>
  )
}
