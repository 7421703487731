import { useState } from 'react';
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { navigationLogOut } from '../utils/constants';
import { Link} from 'react-scroll'


/* interface Props {
  name: string;
  href: string;
  Component: () => JSX.Element;
  active: boolean;
  Icon?: any
} */

const textStyles = {
  activePage: 'text-sky-600 font-bold px-3 py-2 rounded-md text-sm',
  inactivePage: 'text-sky-300 font-bold hover:text-sky-600 px-3 py-2 rounded-md text-sm'
}

export default function NavbarBase() {
  /* const navigate = useNavigate(); */
  const path = window.location.pathname;
  const [navigationData, setNavigationData] = useState(navigationLogOut)

  const changeActive = (position: number) => {
    const tempData = [...navigationData]
    tempData.forEach((e, i) => {
      e.active = false;
    })
    tempData[position].active = true;
    setNavigationData(tempData)
  }

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 lg:justify-center justify-start lg:ml-0 ml-5 w-9/12 sm:items-stretch">

                <div className="flex items-center flex-shrink-0 text-white mr-6 justify-center w-1/12">
                  <a href="/home">
                    <img
                      className="block h-auto w-auto lg:hidden"
                      src="./Logo AC seguros.png"
                      alt="Logo AC seguros"
                    />
                  </a>
                  <a href="/home">  
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="./Logo AC seguros.png"
                      alt="Logo AC seguros"
                    />
                  </a>
                </div>

                <div className="hidden sm:ml-6 sm:block w-8/12 cursor-pointer">
                  <div className="flex lg:items-center ">
                    {path === '/home' ? navigationData.map((item, i) => (
                      item.show ? <div key={i} className={`${item.active ? textStyles.activePage : textStyles.inactivePage} w-3/12 text-center`}>
                        <Link activeClass="active" to={item.place} spy={true} smooth={true} offset={-150} duration={500}>
                          <span
                            key={item.name}
                            onClick={() => changeActive(i)}
                          >
                            <p>{item.href === "login" ? null : item.name}</p>
                          </span>
                        </Link>
                      </div> : ''
                    )) : 
                    navigationData.map((item, i) => (
                      item.show ? <div className={`${item.active ? textStyles.activePage : textStyles.inactivePage} w-3/12 text-center`}>
                        <a href={item.href ? item.href : "/#"} >
                          <span
                            key={item.name}
                            onClick={() => changeActive(i)}
                          >
                            <p>{item.href === "login" ? null : item.name}</p>
                          </span>
                        </a>
                      </div> : ''
                    ))
                    }
                  </div>
                </div>
              </div>

              <div className="relative mr-5 mb-5">
                <div className="absolute inset-y-0 top-0 right-0 items-center sm:hidden ">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>

              {/* <div className='lg:block hidden'>
                <div className="flex morado2 w-[350px] items-end absolute right-0 top-0">
                  <div className='flex justify-center self-center w-10/12'>
                      <button className="flex items-center justify-center px-4 py-2 naranja1 text-white font-bold" onClick={() => navigate('/login')}>
                        <span className="mr-2"><ArrowRightOnRectangleIcon className="h-6 w-6 " aria-hidden="true" /></span>
                        <span>MI CUENTA AC</span>
                      </button>
                  </div>
                  <div className='w-2/12 h-16'>
                    <img
                      className="block h-16 w-18"
                      src="./Buho.png"
                      alt="Your Company"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <Disclosure.Panel className="md:block lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              <div className='flex flex-row justify-center items-center flex-wrap'>
                {navigationData.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href ? item.href : "/#"}
                    className=' px-3 py-2'
                  >
                    <p className='text-sky-500 text-base font-bold '> {item.name} </p>
                  </Disclosure.Button>
                ))}
              </div>

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
