import React from 'react'

export const Privacidad = () => {
    return (
        <div className='flex flex-col justify-center py-10'>
            <div className='lg:flex lg:flex-col w-7/12 self-center'>
                <div><br /><br />
                    <h1 className="text-center font-bold text-base text-4xl">
                        Aviso de privacidad<br /><br />
                    </h1>
                </div><br /><br />
                De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, Asesores y consultores en administración de riesgos S.C.pone a su disposición el siguiente aviso de privacidad.<br /><br />

                Asesores y consultores en administración de riesgos S.C, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.<br /><br />

                Además de lo anterior, informamos a usted que Asesores y consultores en administración de riesgos S.C.,tiene su domicilio ubicado en:<br /><br />

                Joaquín Arrieta 134, Vallarta, 44130 Guadalajara, Jal.<br /><br />

                Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para atender los servicios y/o pedidos que solicite:<br /><br />

                Brindarle un mejor servicio y eficientar los process para que usted tenga una experiencia mucho más placentera.<br /><br />

                Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:<br /><br />

                Nombre completo, edad, sexo, número telefónico, correo electrónico y domicilio.<br /><br />

                Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.<br /><br />

                Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.<br /><br />

                Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva por escrito, mediante el envío de una carta o solicitud en formato libre a la siguiente dirección:<br /><br />

                Joaquín Arrieta 134, Vallarta, 44130 Guadalajara, Jal.<br /><br />

                Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:<br /><br />

                Nombre completo, edad, sexo, número telefónico, correo electrónico domicilio, contenido o motivo de la solicitud y firma.<br /><br />

                En todo caso la respuesta a la solicitud se dará en el siguiente plazo: 15 días hábiles.<br /><br />

                Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:<br /><br />

                a) Nombre del responsable: Departamento de atención al cliente<br />
                b) Domicilio: Joaquín Arrieta 134, Vallarta, 44130 Guadalajara, Jal.<br />
                c) Teléfono: +523336167046<br />
                d) Correo electrónico: asesores.admon70@gmail.com<br /><br />

                Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.<br /><br />

                Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento<br />
                implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.<br /><br />

                Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva por escrito, mediante el envío de una carta o solicitud en formato libre a la siguiente dirección:<br /><br />

                Joaquín Arrieta 134, Vallarta, 44130 Guadalajara, Jal.<br /><br />

                Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal, sin embargo, estas solicitudes deberán contener la siguiente información:<br /><br />

                Nombre completo, edad, sexo, número telefónico, correo electrónico, motivo de la solicitud y firma.<br /><br />

                En cualquier caso, la respuesta a las peticiones se dará a conocer en el siguiente plazo: 15 días.<br /><br />

                Pueden darse cambios en nuestro modelo de negocio, por lo cual, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través del siguiente correo electrónico:<br /><br />

                asesores.admon70@gmail.com<br /><br />

                Última actualización:<br />
                20/02/2023<br />
            </div>
        </div>
    )
}
