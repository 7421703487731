import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './MyTextInput';
import { server } from '../utils/connection';
import { alert } from '../utils/alert';
import convertBase64 from '../utils/convertBase64';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiFillCheckCircle } from 'react-icons/ai';
import MyDateInput from './MyDateInput';

export const CreateInvoice = () => {
    const [status, setStatus] = useState(false)
    const [fileName, setFileName] = useState('')

    return (
        <div className='flex flex-col w-full'>
            <div className='w-full blue1 p-3 px-8'>
                <h1 className='text-white font-bold'>SUBIR FACTURA</h1>
            </div>
            <div className='p-8'>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        "policyNumber": "",
                        "razonSocial": "",
                        "paymentDate": "",
                        "amount": "",
                        "emailSentDate": "",
                        "email": "",
                        "fileData": ""
                    }}
                    onSubmit={async (values) => {
                        setStatus(true)
                        let fileData: any = await convertBase64(values.fileData);
                        try {
                            await server(true).post(`/payment/invoice/upload`, {
                                "policyNumber": values.policyNumber,
                                "razonSocial": values.razonSocial,
                                "paymentDate": values.paymentDate,
                                "amount": values.amount,
                                "emailSentDate": values.emailSentDate,
                                "email": values.email,
                                "fileName": values.fileData['name'],
                                "fileData": fileData.split(',')[1]
                            });
                            alert('Recibo creado correctamente.', 'success', '#73A3D5', 1500)
                            setStatus(false)
                        } catch (error) {
                            alert('Hubo un error al crear el recibo.', 'error', '#c35353', 2000)
                            setStatus(false)
                        }
                    }}
                    validationSchema={
                        Yup.object({
                            policyNumber: Yup.string()
                                .required('Campo requerido'),
                            razonSocial: Yup.string()
                                .required('Campo requerido'),
                            paymentDate: Yup.string()
                                .required('Campo requerido'),
                            amount: Yup.string()
                                .required('Campo requerido'),
                            /* emailSentDate: Yup.string()
                                .required('Campo requerido'), */
                            email: Yup.string()
                                .required('Campo requerido'),
                            fileData: Yup.string()
                                .required('Campo requerido'),
                        })
                    }
                >
                    <Form>
                        <div className='flex lg:flex-row flex-col w-full'>
                            <div className='w-6/12 space-y-4'>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Numero de poliza</h1>
                                    <MyTextInput type="text" className="gris1 h-8 px-4 py-2 border-none" name="policyNumber" label={''} />
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Razón social</h1>
                                    <MyTextInput type="text" className="gris1 h-8 px-4 py-2 border-none" name="razonSocial" label={''} />
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Fecha de pago</h1>
                                    {/* <Field name="paymentDate" placeholder='Elige el pdf' >
                                        {({ field, form }) => (
                                            <div>
                                                <input
                                                    type="date"
                                                    onChange={(event) => {
                                                        form.setFieldValue('paymentDate', event.currentTarget.value);
                                                    }}
                                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                                />
                                            </div>
                                        )}
                                    </Field> */}
                                    <MyDateInput label='' class="gris1 h-8 px-4 py-2 border-none w-56" name="paymentDate" />
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Correo</h1>
                                    <MyTextInput type="email" className="gris1 h-8 px-4 py-2 border-none" name="email" label={''} />
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Fecha de envio de correo</h1>
                                    {/* <Field name="emailSentDate" placeholder='Elige el pdf' >
                                        {({ field, form }) => (
                                            <div>
                                                <input
                                                    type="date"
                                                    onChange={(event) => {
                                                        form.setFieldValue('emailSentDate', event.currentTarget.value);
                                                    }}
                                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
                                            </div>
                                        )}
                                    </Field> */}
                                    <MyDateInput label='' class="gris1 h-8 px-4 py-2 border-none w-56" name="emailSentDate" />
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-4/12 gris2'>Importe</h1>
                                    <MyTextInput type="number" className="gris1 h-8 px-4 py-2 border-none" name="amount" label={''} />
                                </div>
                            </div>
                            <div className='w-6/12'>
                                <Field name="fileData" placeholder='Elige el pdf'>
                                    {({ field, form }) => (
                                        <div className="flex items-center justify-center w-full">
                                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-[350px] h-56 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 z-10">
                                                {fileName ? <div className='relative w-full '>
                                                    <div className='absolute top-[-60px] right-[-30px] '>
                                                        <AiFillCheckCircle className='text-5xl blue2 z-40' />
                                                    </div>
                                                </div> : ''}
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <BsFiletypePdf className='text-6xl blue2' />

                                                    <p className="mb-2 text-sm blue2 dark:text-gray-400 mt-2"><span className="font-semibold">Click para subir archivo.</span></p>
                                                    <p className="text-xs blue2 dark:text-gray-400">{fileName ? fileName : 'SOLO PDF'}</p>
                                                </div>
                                                <input id="dropzone-file" type="file" className="hidden" onChange={(event) => {
                                                    form.setFieldValue('fileData', event.currentTarget.files[0]);
                                                    setFileName(event.currentTarget.files[0]['name'])
                                                }}
                                                    accept="application/pdf" />
                                            </label>
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className='flex justify-center w-full mt-10'>
                            <button type="submit" className="naranja1 text-white py-2 px-8 rounded-md font-bold" disabled={status}> {
                                status ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin color-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg> : "SUBIR"}
                            </button>
                        </div>
                    </Form>
                </Formik >
            </div >

        </div >
    )
}
