import { useEffect, useState } from 'react'
import { Title } from '../components/Title'
import { UserName } from '../components/UserName';
import { FaFileDownload } from 'react-icons/fa'
import { Cards } from '../components/Cards'
import { server } from '../utils/connection';
import { alert } from '../utils/alert';
import userData from '../utils/getUserData'
import formatDate from '../utils/formatDate'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Accordion } from 'flowbite-react';

export const Pagos = () => {
    const [charges, setCharges] = useState<any>([])
    const [card, setCard] = useState<any>([])
    const [subscriptions, setSubscriptions] = useState<any>([])
    const [loading] = useState(false)

    const getCharges = async () => {
        try {
            let response = await server(true).get(`/payment/stripe/get-charges/${userData().id}`);
            setCharges(response.data.stripeResponse)
        } catch (error) {
            alert('Hubo un error al traer tus tarjetas.', 'error', '#c35353', 2000)
        }
    }

    const getSubs = async () => {
        try {
            let response = await server(true).get(`/payment/stripe/get-subscriptions/${userData().id}`);
            console.log(response)
            setSubscriptions(response.data.stripeResponse)
        } catch (error) {
            alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
        }
    }

    const dataCard = (e: any) => {
        if (e && e.length === 0) {
            return;
        }
        setCard(e[0])
    }

    const deleteSub = async (id: string) => {
        const MySwal = withReactContent(Swal)

        const swalWithBootstrapButtons = MySwal.mixin({
            customClass: {
                confirmButton: 'btn btn-success #3085d6',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Desactivar autopago',
            text: "¿Deseas continuar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, desactivarlo',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await server(true).post(`/payment/stripe/cancel-subscription`, {
                        "subscriptionId": id
                    });
                    alert('El autopago para tu poliza a sido desactivado correctamente.', 'success', '#73A3D5', 1500)
                } catch (error) {
                    alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
                }

            }
        })

    }

    useEffect(() => {
        getCharges()
        getSubs()
    }, [])

    return (
        <div className='flex flex-col justify-center py-10'>
            <div className='lg:flex lg:flex-col w-8/12 self-center'>
                <UserName />
                <Title title="MIS PAGOS" />
            </div>
            <div className='lg:flex lg:flex-row w-8/12 self-center'>
                <div className='flex flex-col mt-10'>
                    <Cards data={dataCard} />
                </div>

                <div className='w-full mt-5'>
                    <div className='px-12'>
                        <h1 className='font-bold'>DATOS DE TARJETA</h1>
                    </div>
                    <div className='bg-white space-x-6 px-12 py-6'>
                        <div className='flex lg:flex-row flex-col'>
                            <div className='lg:w-5/12'>
                                <h1 className='font-bold'>Nombre de la tarjeta:</h1>
                                <p> {userData().name} </p>
                                <h1 className='font-bold mt-5'>Expira:</h1>
                                <h1>{card ? `${card.expMonth < 10 ? `0${card.expMonth}` : card.expMonth}/${card.expYear}` : ''} </h1>
                            </div>
                            <div className='lg:w-7/12 flex flex-col justify-start'>
                                <h1 className='font-bold'>Dirección de facturación:</h1>
                                <p> {`${userData().addressDto.address} ${userData().addressDto.address2} #${userData().addressDto.zipCode}, ${userData().addressDto.city}, ${userData().addressDto.state}, ${userData().addressDto.country}.`} </p>
                            </div>
                        </div>
                    </div>

                    <div className="accordion">
                        <Accordion className='w-full rounded-none ' alwaysOpen={true}>
                            <Accordion.Panel className='rounded-none '>
                                <Accordion.Title className='rounded-none gris6bg' >
                                    <p className='text-xl font-bold'>PÓLIZAS LIGADAS A LA TARJETA</p>
                                </Accordion.Title>
                                <Accordion.Content className='bg-white rounded-none'>
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
                                            <tr>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ? <div className='flex text-white w-full justify-center'>
                                                    <div className='blue1 mb-10 mt-10 p-5'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                    </div>
                                                </div> : ''
                                            }
                                            {
                                                !loading ? <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No cuentas con polizas.</h2></div> : ''
                                            }
                                            {
                                                subscriptions && subscriptions.length > 0 ? subscriptions.map((e: any) => (
                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <td className="px-6 py-4">
                                                            <p className='text-black text-base'>&#x2022; No. {e.created}</p>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <p className='text-black text-base'>{'modelo'}, {'año'}</p>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <p className='text-black text-base'>$ {e.items.data[0].price.unitAmount}.00</p>
                                                        </td>
                                                        <td className="px-6 py-4 cursor-pointer">
                                                            <p className='text-black text-base blue1color' onClick={() => { deleteSub(e.id) }}>Desactivar autopago</p>
                                                        </td>
                                                    </tr>
                                                )) : ''
                                            }
                                        </tbody>
                                    </table>
                                </Accordion.Content>
                            </Accordion.Panel>
                            <Accordion.Panel className='rounded-none '>
                                <Accordion.Title className='rounded-none gris6bg'>
                                    <p className='text-xl font-bold'>HISTORIAL DE PAGOS</p>
                                </Accordion.Title>
                                <Accordion.Content className='gris6bg rounded-none'>
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
                                            <tr>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ? <div className='flex text-white w-full justify-center'>
                                                    <div className='blue1 mb-10 mt-10 p-5'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                    </div>
                                                </div> : ''
                                            }
                                            {
                                                charges && charges.length === 0 && !loading ? <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No cuentas con pagos.</h2></div> : ''
                                            }
                                            {
                                                charges && charges.length > 0 ? charges.map((e: any, i: number) => (
                                                    <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <td className="px-6 py-4">
                                                            <div className='lg:col-span-2 col-span-3'>
                                                                <p className='text-gray-400'> {formatDate(e.created * 1000)} </p>
                                                                <p> Poliza-{e.receiptNumber ? e.receiptNumber : 'PENDIENTE'} </p>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <p className='text-black text-base'>Pagada con &#x2022;&#x2022;&#x2022;&#x2022;{e.paymentMethodDetails.card.last4}</p>
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            <div className='flex flex-col'>
                                                                <a href={e.receiptUrl} target="_blank" rel="noreferrer"><FaFileDownload className="text-3xl text-indigo-500" /></a>
                                                                <p className='text-indigo-500 text-xs'>Factura</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 cursor-pointer">
                                                            <p className='text-gray-500'>$ {e.amount / 100} </p>
                                                        </td>
                                                    </tr>
                                                )) : ''
                                            }
                                        </tbody>
                                    </table>
                                </Accordion.Content>
                            </Accordion.Panel>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}
