import { Card } from '../components/Card'
import { useEffect, useRef, useState } from 'react';
import { NewPolicy } from '../components/NewPolicy';
import { UserName } from '../components/UserName';
import { Title } from '../components/Title';
import { NewOldFilters } from '../components/NewOldFilters';
import userData from '../utils/getUserData';
import { server } from '../utils/connection';
import { alert } from '../utils/alert';
import { Modal } from '../components/Modal';
import { PayPolicie } from '../components/PayPolicie';
import { NewCardModal } from '../components/NewCardModal';

export const Polizas = () => {
    const [data, setData] = useState([])
    const [saved, setSaved] = useState([])
    const [loading, setLoading] = useState(false)
    const [noData, setNodata] = useState(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [addCard, setAddCard] = useState<boolean>(false)
    const [currentPolicie, setCurrentPolicie] = useState<any>([])


    const cardElement = useRef<HTMLHeadingElement>(null);

    const getPolicies = async () => {
        setLoading(true)
        try {
            let response = await server(true).post('/pdf-bot/get-policy',
                {
                    "clientId": userData().id
                });
            if (response.data && response.data.status !== 'ERROR' && response.data.policies.length > 0) {
                setLoading(false)
                setSaved(response.data.policies)
                return setData(response.data.policies)
            }
            setLoading(false)
            setNodata(true)
        } catch (error) {
            setLoading(false)
            alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
            setNodata(true)

        }
    }

    const createPolicie = (e) => {
        let policie = data.filter((element) => element.policyNumber === e)
        setCurrentPolicie(policie)
        setVisible(true)
    }

    const getData = (data: any) => {
        if (data.length > 0) {
            setNodata(false)
            return setData(data)
        }
        setNodata(true)
        setData([])
    }

    const load = async () => {
        try {
            let response = await server(true).get(`/payment/stripe/get-cards/${userData().id}`);
            if (!response && response.data.stripeResponse.length === 0) {
                return;
            }
            setAddCard(false)
        } catch (error) {
            alert('Hubo un error al traer tus tarjetas.', 'error', '#c35353', 2000)
        }
    }

    const reloadPolicies = async () => {
        getPolicies()
    }

    const newCard = () => {
        setVisible(false)
        setAddCard(true)
    }

    useEffect(() => {
        getPolicies()
    }, [])

    return (
        <div className='flex flex-col justify-center py-10'>

            <div className='flex flex-col w-10/12 self-center'>
                <UserName />
                <div className='lg:flex lg:flex-row'>
                    <Title title="MIS PÓLIZAS" />
                    <NewOldFilters getData={getData} newData={saved.length > 0 ? saved : []} reference={cardElement} fieldStart={"vigenciaInicio"} fieldEnd={"vigenciaFin"} />
                </div>
            </div>

            <div className='lg:flex hidden'>
                <div className='flex lg:justify-center w-full '>
                    <div ref={cardElement} className='flex flex-wrap lg:w-10/12 w-full place-items-center'>
                        {
                            loading ? <div className='flex text-white w-full justify-center'>
                                <div className='blue1 mb-10 mt-10 p-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                </div>
                            </div> : ''
                        }
                        {
                            noData && data.length === 0 && !loading ? <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No cuentas con polizas.</h2></div> : ''
                        }
                        {
                            !loading && data.length > 0 ? data.map((e: any, i: number) => (
                                <div key={i} className='py-5 px-2'>
                                    <Card
                                        name={e.name}
                                        concepto={e.concept}
                                        ramo={e.type}
                                        compania={e.company}
                                        poliza={e.policyNumber}
                                        vigenciaInicio={e.dateFrom}
                                        vigenciaFin={e.dateUntil}
                                        prima={e.totalPremium}
                                        tipoPrima={e.type}
                                        pdf={e.fileData}
                                        payment={e.isFirstPayment}
                                        create={createPolicie}
                                    ></Card>
                                </div>
                            )) : ''
                        }

                    </div>
                </div>
            </div>

            <div className='lg:hidden block'>
                <div ref={cardElement} className='flex flex-col w-full justify-center'>
                    {
                        loading ? <div className='flex text-white w-full justify-center'>
                            <div className='blue1 mb-10 mt-10 p-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div>
                        </div> : ''
                    }
                    {
                        noData && data.length === 0 && !loading ? <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No cuentas con polizas.</h2></div> : ''
                    }
                    {
                        data.map((e: any, i) => (
                            <div key={i} className=' py-5' >
                                <Card
                                    concepto={e.concept}
                                    ramo={e.type}
                                    compania={e.company}
                                    poliza={e.policyNumber}
                                    vigenciaInicio={e.dateFrom}
                                    vigenciaFin={e.dateUntil}
                                    prima={e.totalPremium}
                                    tipoPrima={e.type}
                                    pdf={e.fileData}
                                    create={createPolicie}
                                ></Card>
                            </div>
                        ))
                    }
                </div>

            </div>



            <div className='flex items-center morado4-bg justify-center w-full h-18 mt-12 mb-12'>
                <div className='relative'>
                    <div className="flex items-center justify-center mb-20">
                        <div className="top side"></div>
                        <div className="left side border-8 border-[#7873e5] flex items-center justify-center text-center">
                            <p className='text-white'> <span className='font-bold'>Automatiza tu pago</span> y despreocupate de estar
                                haciendo los pagos nosotros lo hacemos por ti! </p>
                        </div>
                        <div className="right side"></div>
                    </div>
                </div>
            </div>

            <NewPolicy />

            <Modal isVisible={visible} onClose={() => { setVisible(false) }}  >
                <PayPolicie policie={currentPolicie} reload={reloadPolicies} newCard={newCard} />
            </Modal>

            <Modal isVisible={addCard} onClose={() => setAddCard(false)}>
                <NewCardModal load={load} close={() => { setAddCard(false) }} />
            </Modal>
        </div>
    )
}
