import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { server } from '../utils/connection';
import userData from '../utils/getUserData';
import { SubmitButton } from './SubmitButton';
import { alert } from '../utils/alert';
const stripePromise = loadStripe('pk_test_51Lr3kvLMma8Le67OGZYQT4wuWj3IQKwyRiQBpukpHlzIXjYAL5DYUr3zLHZ4hj7qzLbX7lS1cUc7ZbscrjhsIm5700hnViQn0U');

export const NewCardModal = ({ load, close }: any) => {
    const [card, setCard] = useState<any>()
    const [state, setState] = useState(false)

    const loadCard = async () => {
        const Stripe = await stripePromise;
        if (!Stripe) {
            return alert('Hubo un error al cargar Stripe.', 'error', '#c35353', 2000);
        }
        const elements = Stripe.elements();
        const card = elements.create('card');
        setCard(card)
        return card.mount('#card-element');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const Stripe = await stripePromise;
        let token = await Stripe.createToken(card)
        if (!token || !token.token.id) {
            return alert('Hubo un error al subir tu tarjeta.', 'error', '#c35353', 2000);
        }
        setState(true)
        try {
            await server(true).post(`/payment/stripe/create-card`, {
                "userId": userData().id,
                "token": token.token.id
            });
            setState(false)
            load()
            alert('Tarjeta creada correctamente', 'success', '#73A3D5')
        } catch (error) {
            setState(false)
            return alert('Hubo un error al subir tu tarjeta.', 'error', '#c35353', 2000);
        }
    };

    useEffect(() => {
        loadCard()
    }, [])

    return (
        <div>
            <div className="modal-header flex flex-shrink-0 items-center justify-between blueModal">
                <div className='w-full blue1 p-3 px-8'>
                    <h1 className='text-white font-bold'>Agregar una tarjeta de credito o debito</h1>
                </div>
            </div>
            <div>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <div className="w-full p-12" id="card-element" >
                    </div>
                    <div className='w-full flex justify-center mb-5 space-x-5'>
                        <button className='morado2 text-white p-3 w-40 rounded-md font-bold' onClick={() => {close()} } >Cancelar</button>
                        <SubmitButton status={state} text={'Agregar Tarjeta'} class='naranja1 text-white px-6 rounded-md w-30 py-3 font-bold' />
                    </div>
                </form>
            </div>
        </div>
    )
}
