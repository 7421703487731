import { useState } from 'react'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MyTextInput from './MyTextInput';
import { server } from '../utils/connection';
import { alert } from '../utils/alert';
import convertBase64 from '../utils/convertBase64';
import MyDateInput from './MyDateInput';
import { BsFiletypePdf } from 'react-icons/bs';
import MySelectInput from './MySelectInput';
import { AiFillCheckCircle } from 'react-icons/ai';

export const EditPolicie = ({ policie, current, reload }) => {
    const [corps] = useState<any>([])
    const [status, setStatus] = useState(false)
    /* const [sub, setSub] = useState(false) */
    const [type, setType] = useState([])
    const [fileError, setFileError] = useState(false)
    /* const [charge, setChatgeType] = useState([
        {
            value: 'CHARGE',
            displayName: 'Pago único'
        },
        {
            value: 'SUBSCRIPTION',
            displayName: 'Autopago'
        }]) */
    /* const [cards, setCards] = useState([]) */
    const [fileName, setFileName] = useState(policie[0].fileName)

    /*  const getCorps = async () => {
        try {
            let response = await server(true).get(`/pdf-bot/companies-policies`);
            setCorps(response.data)
            let filteredCorp = response.data.filter((corpElement) => corpElement.company.id == policie[0].company.id);
            setType(filteredCorp[0].policiesType)
        } catch (error) {
            alert('Hubo un error al traer las compañias.', 'error', '#c35353', 2000)
        }
    }

    const getPolicie = async () => {
        try {
            let response = await server(true).post(`/pdf-bot/get-policy`, {
                "policyNumber": policie
            });
            if (response.data.statu === 'ERROR') {
                return;
            }
            let addedProp = []
            let data = response.data.stripeResponse
            data.forEach(element => {
                element['checked'] = false
                addedProp.push(element)
            });
            setCards(addedProp)
        } catch (error) {
            console.log(error)
        }
    } */

   /*  const updatedChecked = (item) => {
        setCards(current =>
            current.map(obj => {
                if (item.checked && item.id === obj.id) {
                    return { ...obj, checked: false };
                }
                if (item.id === obj.id) {
                    return { ...obj, checked: true };
                }
                return obj;
            }),
        );
    } */

    const selected = (e) => {
        let filtered = corps.filter((corpElement) => corpElement.company.id === e.target.value);
        setType(filtered[0].policiesType)
    }

    /* const subActive = (e) => {
        if (e.target.value === 'SUBSCRIPTION') {
            setSub(true)
        }
    } */

    /* const validateCharge = () => {
        if (policie[0].chargeType === 'SUBSCRIPTION') {
            setSub(true)
        }
    } */

    /* useEffect(() => {
        getCorps()
        validateCharge()
    }, []) */

    return (
        <div className='flex flex-col'>
            <div className='w-full blue1 p-3 px-8'>
                <h1 className='text-white font-bold'>EDITAR PÓLIZA</h1>
            </div>
            <div className='p-8'>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        "clientId": policie[0].id,
                        "companyId": policie[0].company.id,
                        "policyNumber": policie[0].policyNumber,
                        "chargeType": policie[0].chargeType,
                        "dateFrom": policie[0].dateFrom,
                        "dateUntil": policie[0].dateUntil,
                        "concept": policie[0].concept,
                        "totalPremium": policie[0].totalPremium,
                        "premiumType": policie[0].premiumType,
                        "type": policie[0].type,
                        "fileName": policie[0].fileName,
                        "billingPeriod": policie[0].billingPeriod,
                        "fileData": policie[0].fileData
                    }}
                    onSubmit={async (values) => {
                        if (!values.fileData) {
                            return setFileError(true)
                        }
                        let fileData: any = values.fileData
                        let fileName = values.fileName
                        if (values.fileData !== policie[0].fileData) {
                            fileName = values.fileData['name']
                            fileData = await convertBase64(values.fileData);
                            fileData = fileData.split(',')[1];
                        }
                        setStatus(true)
                        try {
                            await server(true).put(`/pdf-bot/upload-policy/${policie[0].id}`, {
                                "policyNumber": values.policyNumber,
                                "clientId": values.clientId,
                                "companyId": values.companyId,
                                "chargeType": 'CHARGE'/* values.chargeType */,
                                "dateFrom": values.dateFrom,
                                "concept": values.concept,
                                "dateUntil": values.dateUntil,
                                "totalPremium": values.totalPremium,
                                /* "premiumType": values.premiumType,
                                "billingPeriod": values.billingPeriod, */
                                "type": values.type,
                                "fileName": fileName,
                                "fileData": fileData
                            });
                            alert('Poliza actualizada correctamente.', 'success', '#73A3D5', 1500)
                            reload()
                            setStatus(false)
                        } catch (error) {
                            alert('Hubo un error al crear la poliza.', 'error', '#c35353', 2000)
                            setStatus(false)
                        }
                    }}
                    validationSchema={
                        Yup.object({
                            concept: Yup.string()
                                .required('Campo requerido'),
                            policyNumber: Yup.string()
                                .required('Campo requerido'),
                            dateFrom: Yup.string()
                                .required('Campo requerido'),
                            dateUntil: Yup.string()
                                .required('Campo requerido'),
                            totalPremium: Yup.string()
                                .required('Campo requerido'),
                            type: Yup.string()
                                .required('Campo requerido'),
                            /* premiumType: Yup.string()
                                .required('Campo requerido'),
                            chargeType: Yup.string()
                                .required('Campo requerido'), */
                            companyId: Yup.string()
                                .required('Campo requerido'),
                            /* billingPeriod: Yup.string()
                                .required('Campo requerido'), */
                        })
                    }
                >
                    <Form>
                        <div className='flex lg:flex-row flex-col w-full'>
                            <div className='lg:w-7/12 w-full space-y-4'>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12'>Concepto</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="text" className="gris1 px-4 py-2 border-none w-full" placeholder='Toyota Tacoma, 2018' name="concept" label={''} />
                                    </div>
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12' >Compañia</h1>
                                    <div className='w-7/12'>
                                        <MySelectInput data={corps} level1={'company'} valueOpt={'id'} displayName={'companyName'} class='border-none gris1 w-full' label={''} name={'companyId'} selected={selected} />
                                    </div>
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12'>Ramo</h1>
                                    <div className='w-7/12'>
                                        <MySelectInput data={type} valueOpt={'policyTypeName'} displayName={'policyTypeName'} class='border-none gris1 w-full' label={''} name={'type'} disabled={type.length > 0 ? false : true} />
                                    </div>
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12'>Póliza</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="text" className="gris1 px-4 py-2 border-none w-full" name="policyNumber" placeholder='No. póliza' label={''} />
                                    </div>
                                </div>
                                <div className='flex space-x-4 just items-center'>
                                    <h1 className='py-2 w-3/12'>Vigencia</h1>
                                    <div className='flex space-x-2 items-center'>
                                        <div className='w-5/12'>
                                            <MyDateInput label='' class="gris1 h-8 px-4 border-none w-full" name="dateFrom" />
                                        </div>
                                        <p>al</p>
                                        <div className='w-5/12'>
                                            <MyDateInput label='' class="gris1 h-8 px-4 border-none" name="dateUntil" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12'>Tipo de pago</h1>
                                    <div className='w-6/12'>
                                        <MySelectInput data={charge} valueOpt={'value'} displayName={'displayName'} class='border-none gris1 w-full' label={''} name={'chargeType'} selected={subActive} />
                                    </div>
                                </div> */}
                                {/* {
                                    sub ? <div className='flex space-x-4'>
                                        <h1 className='py-2 w-3/12'>Periodo</h1>
                                        <div className='w-6/12'>
                                            <Field as="select" name="billingPeriod" className="border-none gris1 w-full">
                                                <option> Elige una empresa... </option>
                                                <option value='DAY'> Diario </option>
                                                <option value='WEEK'> Semanal </option>
                                                <option value='MONTH'> Mensual </option>
                                                <option value='YEAR'> Anual </option>
                                            </Field>
                                        </div>
                                    </div> : null
                                } */}
                                <div className='flex space-x-4 items-center'>
                                    <h1 className='py-2 w-3/12'>Prima</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="number" className="gris1 px-4 py-2 border-none w-full" placeholder='$' name="totalPremium" label={''} />
                                    </div>
                                    {/* <div className='w-[150px]'>
                                        <MyTextInput type="text" className="gris1 px-4 py-2 border-none w-full" placeholder='Trimestral' name="premiumType" label={''} />
                                    </div> */}
                                </div>
                            </div>
                            <div className='w-4/12 flex flex-col items-start'>

                                <Field name="fileData" placeholder='Elige el pdf' >
                                    {({ field, form }) => (
                                        <div className="flex items-center justify-center w-full">
                                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-[400px] h-56 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 z-10">
                                                {fileName ? <div className='relative w-full '>
                                                    <div className='absolute top-[-60px] right-[-30px] '>
                                                        <AiFillCheckCircle className='text-5xl blue2 z-40' />
                                                    </div>
                                                </div> : ''}
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <BsFiletypePdf className='text-6xl blue2' />

                                                    <p className="mb-2 text-sm blue2 dark:text-gray-400 mt-2"><span className="font-semibold">Click para subir archivo.</span> </p>
                                                    <p className="text-xs blue2 dark:text-gray-400">{fileName ? fileName : 'SOLO PDF'}</p>
                                                </div>
                                                <input id="dropzone-file" type="file" className="hidden" onChange={(event) => {
                                                    setFileError(false)
                                                    form.setFieldValue('fileData', event.currentTarget.files[0]);
                                                    setFileName(event.currentTarget.files[0] ? event.currentTarget.files[0]['name'] : fileName)
                                                }}
                                                    accept="application/pdf" />
                                            </label>
                                        </div>
                                    )}
                                </Field>
                                {fileError ? <div className='flex flex-col w-full justify-center items-center'><span className="text-red-600 font-bold "> Archivo requerido </span></div> : ''}
                            </div>
                        </div>
                        {/* <div className='flex flex-col space-x-4 mt-4'>
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-separate border-spacing-y-4">
                                <thead className="text-xs text-gray-700 uppercase ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 gris2 text-base">
                                            Mis Tarjetas
                                        </th>
                                        <th scope="col" className="px-6 py-3 gris2 text-base">
                                            Expira
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        cards.map((e) => (
                                            <tr className={`${e.checked ? 'blue1 text-white' : 'gris1 text-black'} text-base cursor-pointer`}>
                                                <td className='px-5'>
                                                    <div className='flex items-center space-x-4' onClick={() => {
                                                        let validate = cards.some((e) => e.checked)
                                                        let filtered = cards.filter((e) => e.checked)
                                                        if (validate && !e.checked) {
                                                            updatedChecked(filtered[0])
                                                            updatedChecked(e)
                                                            setCard(e.last4)
                                                        }
                                                        if (validate && filtered[0].id !== e.id) {
                                                            return;
                                                        }
                                                        updatedChecked(e)
                                                        setCard(e.last4)
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            value={e.id}
                                                            className={`${e.checked ? 'circle-check' : 'circle'}`}
                                                            name="cardNumbers"
                                                            disabled />
                                                        <img className="h-10 w-10" src={`./${e.brand}.png`} alt="" />
                                                        <p>{e.brand}, {e.funding === 'credit' ? 'Credito' : 'Débito'} con terminación &#x2022;&#x2022;&#x2022;&#x2022;{e.last4}</p>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    {`${e.expMonth < 10 ? `0${e.expMonth}` : e.expMonth}/${e.expYear}`}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                            {cardError ? <div className='flex flex-col w-full justify-center items-center'><span className="text-red-600 font-bold "> Tarjeta requerida </span></div> : ''}

                        </div> */}
                        <div className='flex justify-center w-full mt-10'>
                            <button type="submit" className="naranja1 text-white py-2 px-8 rounded-md font-bold" disabled={status}> {
                                status ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin color-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg> : "ACTUALIZAR"}
                            </button>
                        </div>
                    </Form>
                </Formik >
            </div >

        </div >
    )
}
