import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import MyTextInput from './MyTextInput';
import * as Yup from 'yup';
import { gapi } from 'gapi-script';
import { server } from '../utils/connection'
import { PhoneCard } from './PhoneCard';
import { useNavigate } from "react-router-dom";
import { alert } from '../utils/alert'

const clientId = '1031179784844-04jlklddaq0lq9itv7tf5sl4hp0ae3v3.apps.googleusercontent.com'


export const Login = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);

  });

  return (
    <div className="flex flex-col lg:flex-row justify-center sm:items-start md:items-start lg:items-center h-screen bg-white py-20">
      <div className='flex lg:flex-row flex-col'>
        <Formik
          initialValues={{
            name: '',
            password: '',
          }}
          onSubmit={async (values) => {
            setLoading(true)
            try {
              let response = await server(false).post('/login',
                {
                  "username": values.name,
                  "password": values.password
                }
              )
              localStorage.setItem('logged', "true");
              localStorage.setItem('userData', JSON.stringify(response.data));
              localStorage.setItem('userToken', response.data.token);
              alert('Login exitoso!', "success", "#73a3d5", 1500)
              setTimeout(() => {
                setLoading(false)
                setError(false)
                navigate('/poliza')
                window.location.reload()
              }, 1100)
            } catch (error) {
              setLoading(false)
              setError(true)
            }
          }}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .max(15, 'Debe tener 15 caracteres minimo')
                .required('Usuario requerido'),
              password: Yup.string()
                .max(15, 'Debe tener 15 caracteres minimo')
                .required('Contraseña requerida')
            })
          }
        >
          <Form >
            <div className="flex flex-col bg-gray-200 lg:px-0 lg:mr-0 lg:ml-0 mr-4 ml-4">
              <div className='px-8 flex flex-col w-full justify-center'>

                <h1 className='font-bold text-2xl text-center py-5'>Inicia sesión</h1>

                <div className="flex flex-row">
                  <div className='gris3 h-6 flex justify-center w-5/12'>
                    <span className='gris8'>Usuario</span>
                  </div>
                  <MyTextInput label="" name="name" className='bg-white pl-2 h-6 border-none w-full' />
                </div>

                <div className="flex flex-row mt-5">
                  <div className='gris3 h-6 flex justify-center w-5/12'>
                    <span className='gris8'>Contraseña</span>
                  </div>
                  <MyTextInput label="" name="password" type='password' className='bg-white pl-2 h-6 border-none w-full' />
                </div>

                <div className="flex flex-row w-8/12 self-end py-2">
                  <div><p className='text-xs gris8'>¿Olvidaste tu usuario o contraseña?</p></div>
                </div>

                <div className={`py-5 self-center flex ${error ? "" : "hidden"}`}>
                  <p>Usuario ó contraseña incorrectos.</p>
                </div>

                <div className='mb-5 mt-5 self-center'>
                  <button type="submit" className="morado2 text-white py-2 px-8 rounded-sm font-bold" disabled={loading}> {
                    loading ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin color-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg> : "INICIAR SESIÓN"}
                  </button>
                </div>
              </div>
              <div className='grid text-center justify-items-center morado2 w-full lg:mb-0'>
                <p className='text-white text-sm py-5'>
                  Si ya eres cliente y aún no estás <br />
                  registrado con nosotros, contactaté ya <br />
                  con uno de nuestros ejecutivos  o hazlo tu <br /> mismo.
                </p>
                <div className='pb-5 w-8/12'>
                  <button onClick={() => { navigate('/contacto') }} className="naranja1 text-white py-2 rounded-md w-6/12 font-bold"> Contacto</button>
                </div>
              </div>
            </div>
          </Form >
        </Formik>
        <div className='flex lg:w-auto w-full lg:justify-start justify-center lg:items-start items-center'>
          <PhoneCard />
        </div>
      </div>
    </div>
  )
}
