import { Form, Formik } from 'formik';
import MyTextArea from './MyTextArea';
import MyTextInput from './MyTextInput';


interface Props {
    name: string;
    birthDate: string;
    email: string;
    cellPhone: string;
    homePhone: string;
    adress: string;
    rfc?: string;
    corp?: string;
    current?: boolean;
}


export const DataHolder = ({ name, birthDate, email, cellPhone, homePhone, adress, rfc, corp, current }: Props) => {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name,
                birthDate,
                email,
                cellPhone,
                homePhone,
                adress,
                rfc,
                corp,
            }}
            onSubmit={(values) => {
                console.log(values)
            }}
        /* validationSchema={
        Yup.object({
            name: Yup.string()
            .max(15, 'Debe tener 15 caracteres minimo')
            .required('Requerido'),
            lastname: Yup.string()
            .max(15, 'Debe tener 15 caracteres minimo')
            .required('Requerido'),
        })
        } */
        >
            
            <Form>
                <div className='flex lg:flex-row flex-col'>
                    <div className='flex flex-col lg:w-6/12 w-full'>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-6/12'>
                                <p className='text-gray-400 px-3'>Nombre completo</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} name={'aa'} value={name ? name : '-'} class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-6/12'>
                                <p className='text-gray-400 px-3'>Fecha de nacimiento</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} name={'aa'} value={ birthDate ? birthDate : '-'} class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-6/12'>
                                <p className='text-gray-400 px-3'>Correo</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} name={'aa'} value={ email ? email : '-'} class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-6/12'>
                                <p className='text-gray-400 px-3'>No. de celular</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} name={'aa'} value={cellPhone ? cellPhone : '-' } class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-6/12'>
                                <p className='text-gray-400 px-3'>No. de teléfono fijo</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} name={'aa'} value={ homePhone ? homePhone : '-' } class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:w-6/12 w-full lg:ml-5'>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-4/12'>
                                <p className='text-gray-400 px-3'>Dirección</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextArea label={''} value={  adress ? adress : '-' } class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" name={'b'} rows={3} cols={20} disabled/>
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-4/12'>
                                <p className='text-gray-400 px-3'>RFC</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} placeholder={"(Opcional)"} value={ rfc ? rfc : '-'} name={'aa'} class="w-full px-4 text-black gris1 border-1  border-1 border-gray-200" disabled />
                            </div>
                        </div>
                        <div className='flex flex-row items-center py-1'>
                            <div className='w-4/12'>
                                <p className='text-gray-400 px-3'>Empresa</p>
                            </div>
                            <div className='w-7/12'>
                                <MyTextInput label={''} placeholder={"(Opcional)"} value={ corp ? corp : '-'} name={'aa'} class="w-full px-4 text-black gris1 border-1 border-1 border-gray-200" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}
