import { useState, useEffect } from 'react';
import NavbarUsers from './NavbarUsers';
import NavbarBase from './NavbarBase';

export default function Navbar({ check }: any) {
    const [typeNav, setTypeNav] = useState<boolean>(false);
    const logged = localStorage.getItem('logged')
    //* eslint-disable */
    useEffect(() => {
        if (logged && logged === "true") {
            // eslint-disable-next-line
            setTypeNav(true)
            check()
        }
    }, [logged])
    /* eslint-enable */
    return (
        <div className='z-50 w-full mb-8'>
            <div className='fixed top-0 w-full'>
                {
                    typeNav ? <NavbarUsers /> : <NavbarBase />
                }
            </div>
        </div>

    )
}
