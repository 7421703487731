import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { BarControllers } from './BarControllers';
import { UserName } from './UserName';
import { useState } from 'react';

interface Props {
    data: any,
    title: any
    name?: boolean
}

export const BarSimpleComponent = ({ data, title, name }: Props) => {
    const [savedData, setSavedData] = useState([data])
    const [dataBar, setDataBar] = useState([data])
    const [totalBars, setTotalBars] = useState([
        {
            name: 'Gráfica 1',
            color: '#46ae2f'
        }
    ])

    const barElement = (data: any) => {
        let finalData = [];
        if (data.length === 0) {
            return;
        }
        let updatedData = [...dataBar]
        updatedData.forEach((e, i) => {
            e.forEach((element, index) => {
                const date = new Date();
                date.setMonth(element.month - 1);
                let result = date.toLocaleString('es-MX', { month: 'short' })
                updatedData[i][index]['monthName'] = `${result} - ${String(element.year).slice(-2)}`
            });
        })
        finalData.push(updatedData)
        return updatedData
    }

    const getData = (index: number) => {
        let formatedData = barElement(data)
        return formatedData[index]
    }

    const filter = (type: string, index: number) => {
        const today = new Date()
        const tempSavedData = [...savedData]
        const tempDataBar = [...dataBar]
        let newData = []

        switch (type) {
            case "6M":
                let sixMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 12));
                tempSavedData[index].forEach((e) => {
                    let validateDate = new Date(`1-${e.month}-${e.year}`)
                    if (validateDate > sixMonthsAgo && validateDate < today) {
                        newData.push(e)
                    }
                });
                tempDataBar[index] = newData
                setDataBar(tempDataBar)
                break;
            case "1A":
                let oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
                tempSavedData[index].forEach((e) => {
                    let validateDate = new Date(`1-${e.month}-${e.year}`)
                    if (validateDate > oneYearAgo && validateDate < today) {
                        newData.push(e)
                    }
                });
                tempDataBar[index] = newData
                setDataBar(tempDataBar)
                break;
            case "5A":
                let fiveYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
                tempSavedData[index].forEach((e) => {
                    let validateDate = new Date(`1-${e.month}-${e.year}`)
                    if (validateDate > fiveYearsAgo && validateDate < today) {
                        newData.push(e)
                    }
                });
                tempDataBar[index] = newData
                setDataBar(tempDataBar)
                break;
            case "Todo":
                tempDataBar[index] = tempSavedData[index]
                setDataBar(tempDataBar)
                break;
            default:
                break;
        }
    }

    const addChart = (a) => {
        let tempData = [...dataBar]
        let savedDataTemp = [...savedData]
        setSavedData(prev => [...prev, savedDataTemp[0]])
        setDataBar(prev => [...prev, tempData[0]])
        setTotalBars(prev => [...prev, a])
    }

    const deleteChart = (chart) => {
        const updatedChars = [...totalBars];
        updatedChars.splice(chart, 1)
        setTotalBars(updatedChars)
    }

    /* useEffect(() => {
        console.log(dataBar)
    }, [dataBar]) */

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row w-8/12 self-center'>
                <div className='w-3/12'>
                    {name ? <UserName length={"w-full"} /> : ""}
                    <BarControllers showControllers={false} title={title} createChart={addChart} deleteChart={deleteChart} />
                </div>
                <div className='flex flex-col w-9/12 self-center'>
                    {
                        totalBars.length > 0 ? totalBars.map((e, i) => (
                            <div className={`flex flex-col w-full gris1 ml-5 justify-start py-5`}>
                                <div className='flex w-7/12 justify-center mb-5'>
                                    <div className='flex flex-col w-auto'>
                                        <div className='flex flex-row p-2 justify-center gris4 m-2'>
                                            <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("6M", i) }} >6M</p>
                                            <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("1A", i) }} >1A</p>
                                            <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("5A", i) }} >5A</p>
                                            <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("Todo", i) }} >Todo</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='self-center'>
                                    <BarChart
                                        width={950}
                                        height={400}
                                        data={getData(i)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="monthName" />
                                        <YAxis dataKey="amount" />
                                        <Tooltip />
                                        <Bar dataKey={'amount'} fill={e.color} />
                                    </BarChart>
                                </div>
                            </div>
                        )) : ''
                    }
                </div>
            </div>
            {/* <div className='flex flex-row w-8/12 self-center'>
                <div className='flex flex-row w-full justify-end'>
                    <button className='naranja1 text-white font-bold p-3 rounded-md mt-3'>COMPARTIR</button>
                    <button className='naranja1 text-white font-bold p-3 rounded-md ml-3 mt-3'>DESCARGAR REPORTE</button>
                </div>
            </div> */}
        </div>
    )
}
