import { useField } from 'formik'

interface Props {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password' | 'checkbox';
    placeholder?: string;
    [x: string]: any;
    class?: string;
}

const classes = {
    default: "block w-full border-gray-300 py-3 px-4 text-gray-900 bg-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
}

export default function MyDateInput({ label, ...props }: Props) {

    const [field, meta] = useField(props)
    /* const datePickerId = new Date().toISOString().split("T")[0]; */
    return (
        <>
            <div className='grid grid-col-1'>
                <label htmlFor={props.id || props.name}> {label} </label>
                <input className={props.class ? props.class : classes.default}/*  max={datePickerId} */ type="date"  {...field} {...props} />
                {
                meta.touched && meta.error && (
                    <div>
                        <span className="text-red-600 font-bold"> {meta.error} </span>
                    </div>
                )
            }
            </div>
        </>
    )
}
