import './App.css';
import Navigation from './routes/Navigation';
import 'tw-elements';
import { useEffect } from "react";
import { Datepicker, Input, initTE } from "tw-elements";

function App() {
  
useEffect(() => {
  initTE({ Datepicker, Input });
}, []);
  return (
    <>
      <Navigation />
    </>
  );
}

export default App;
