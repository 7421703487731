import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { server } from './connection'
import { alert } from '../utils/alert';

export const validateModal = (updateConfirmation, role, employee, idCurrenRole) => {
    const MySwal = withReactContent(Swal)

    const swalWithBootstrapButtons = MySwal.mixin({
        customClass: {
            confirmButton: 'btn btn-success #3085d6',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    swalWithBootstrapButtons.fire({
        title: 'Actualizar rol de colaborador',
        text: "¿Deseas continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await server(true).delete(`/users/${employee.id}/roles/${idCurrenRole[0].id}`,{});
                await server(true).post(`/users/${employee.id}/roles/${role}`,{});
                alert('Roles actualizados correctamente!', 'success', '#73A3D5')
            } catch (error) {
                alert('Hubo un error al cambiar los roles.', 'error', '#c35353', 2000)
            }
            return updateConfirmation(true);
        } 
        return updateConfirmation(false)
    })
}


