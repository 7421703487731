import { PersonalData } from '../../components/PersonalData'
import { Title } from '../../components/Title'
import { UserName } from '../../components/UserName'
import { ConfigurationAdmin } from '../../components/ConfigurationAdmin';
import userData from '../../utils/getUserData';

export const DatosEjecutivo = () => {
    return (
        <>
            {
                userData().roles[0] === "ROLE_SUPER_ADMIN" || userData().roles[0] === "ROLE_ADMIN" ?
                    <div className='flex flex-col justify-center py-10'>
                        <div className='lg:flex lg:flex-row w-full px-3 justify-center'>
                            <UserName />

                        </div>


                        <div className='lg:flex lg:flex-row w-full px-3 justify-center'>

                            <div className='flex flex-col lg:w-4/12 w-full'>
                                <Title title="MIS DATOS" />
                                <div className='gris1 w-full h-full'>
                                    <PersonalData />
                                </div>
                            </div>
                            <div className='flex flex-col lg:w-4/12 w-full'>
                                <Title title="CONFIGURACIÓN" />
                                <div className='gris3 w-full h-full'>
                                    <ConfigurationAdmin />
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <div className='flex flex-col justify-center py-10'>

                        <div className='lg:flex lg:flex-row w-full px-3 justify-center'>
                            <div className='flex flex-col lg:w-4/12 w-full'>
                                <div className='w-full justify-center'>
                                    <UserName />
                                </div>
                                <Title title="MIS DATOS" />
                                <div className='gris1 w-full h-full'>
                                    <PersonalData />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}
