import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MyTextArea from './MyTextArea';
import { FaCheck } from 'react-icons/fa';
import { PhoneCard } from './PhoneCard';
import { Title } from './Title';
import userData from '../utils/getUserData';
import { alert } from '../utils/alert';
import { server } from '../utils/connection';
import {useState} from 'react';
import { SubmitButton } from './SubmitButton';

export const NewPolicy = () => {
    const [tipo,setTipo] = useState('mas reciente')
    const [block,setBlock] = useState(false)

    return (

        <div className='flex flex-col py-10'>

            <div className='lg:flex lg:flex-row w-8/12 self-center'>

                <div className='lg:flex lg:flex-col lg:w-8/12 sm:w-full self-center'>

                    <Title title="COTIZAR NUEVA PÓLIZA" />


                    <div className='lg:flex lg:flex-row lg:w-4/6 w-full'>
                        <Formik
                            initialValues={{
                                mensaje: '',
                            }}
                            onSubmit={async (values) => {
                                setBlock(true)
                                try {
                                    await server(true).post('/send',
                                        {
                                            "requestType": "EMAIL",
                                            "mailDetailsEntity": {
                                                "sender": "ac-seguros-dev@luyusteahouse.com"/* userData().contactDTO.email */,
                                                "recipient": "panduro.sergio1@gmail.com",
                                                "CC": "panduro.sergio1@gmail.com",
                                                "Bcc": "panduro.sergio1@gmail.com",
                                                "subject": "COTIZAR NUEVA POLIZA",
                                                "body": `USER:${userData().name} <br/>
                                                TIPO: ${tipo}<br/>
                                                MENSAJE: ${values.mensaje}<br/>`,
                                                "signature": "Enviado desde polizas"
                                            }
                                        });
                                    alert('Poliza enviada correctamente!', 'success', '#73A3D5')
                                    setBlock(false)
                                } catch (error) {
                                    setBlock(false)
                                    alert('Hubo un error al enviar la poliza.', 'error', '#c35353', 2000)
                                }
                            }}
                            validationSchema={
                                Yup.object({
                                    mensaje: Yup.string()
                                        .required('Este campo es requerido.')
                                })
                            }
                        >
                            <Form className=" grid grid-cols-1 gap-y-6 sm:gap-x-8">
                                <div className="sm:col-span-2 py-3 mt-5 ">
                                    <p>Ramo de póliza*</p>
                                    <select onChange={(e)=>{setTipo(e.target.value)}} className='gris3 w-56 h-10 px-2 border-none'>
                                        <option value="mas reciente">Más recientes</option>
                                        <option value="mas antiguo">Más antiguo</option>
                                    </select>
                                </div>
                                <div className="sm:col-span-2 w-full">
                                    <MyTextArea label='Escribe tu mensaje aquí*' name='mensaje' placeholder='Me interesa cotizar el seguro....' rows={5} cols={50} />
                                </div>
                                <div className='flex flex-col justify-between lg:flex-row w-full'>
                                    <div className='flex'>
                                        <FaCheck className='text-blue-500'></FaCheck>
                                        <p className='px-2 text-sm'> Te contactaremos lo más pronto posible</p>
                                    </div>
                                    <div className='flex justify-end items-end'>
                                        <SubmitButton status={block} text={'ENVIAR'} />
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
                <PhoneCard />
            </div>

        </div>

    )
}