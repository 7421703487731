import { useState } from 'react';
import { Disclosure } from '@headlessui/react'
import { ArrowRightOnRectangleIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink, useNavigate } from 'react-router-dom';
import { navigationAdmin, navigationUser } from '../utils/constants';

const textStyles = {
    activePage: 'text-sky-600 font-bold px-3 py-2 rounded-md text-sm ',
    inactivePage: 'text-sky-300 font-bold px-3 py-2 rounded-md text-sm'
}

export default function NavbarUsers() {
    const [navigationData, setNavigationData] = useState<any>()
    const navigate = useNavigate();
    const changeActive = (item: string, position: number) => {
        if (item === 'logout') {
            localStorage.removeItem('userData')
            localStorage.removeItem('type')
            localStorage.removeItem('userToken')
            localStorage.removeItem('users')
            localStorage.setItem("logged", "false")
            navigate('/login');
            window.location.reload();
        }
        const tempData = [...navigationData]
        tempData.forEach((e, i) => {
            e.active = false;
        })
        tempData[position].active = true;
        setNavigationData(tempData)
    }

    const getNavData = () => {
        const type = localStorage.getItem('type')
        if (type === "admin") {
            setNavigationData(navigationAdmin)
            return navigationAdmin;
        } else {
            setNavigationData(navigationUser)
            return navigationUser;
        }
    }

    return (
        <Disclosure as="nav" className="bg-white sticky">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-full ">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex flex-1 gap-12 lg:justify-center justify-start lg:ml-0 ml-5 w-9/12 sm:items-stretch">
                                <div className="flex flex-shrink-0 items-center">
                                    <a href="/home">
                                        <img
                                            className="block h-8 w-auto lg:hidden"
                                            src="./Logo AC seguros.png"
                                            alt="Your Company"
                                        />
                                    </a>
                                    <a href="/home">
                                        <img
                                            className="hidden h-8 w-auto lg:block"
                                            src="./Logo AC seguros.png"
                                            alt="Your Company"
                                        />
                                    </a>
                                </div>

                                <div className="hidden sm:ml-6 sm:block cursor-pointer">
                                    <div className="flex sm:gap-5 lg:gap-20 lg:items-center">
                                        {getNavData().map((item, i) => (
                                            <>
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className={item.active ? textStyles.activePage : textStyles.inactivePage}
                                                    onClick={() => changeActive(item.href, i)}
                                                >
                                                    {item.href === "login" ? null : item.name}

                                                    {
                                                        item.href === "login" ? <button
                                                            type="button"
                                                            className="flex flex-row font-bold items-center rounded-full p-1 text-sky-500 focus:ring-2 focus:ring-offset-2"
                                                        >
                                                            {item.name}
                                                            <ArrowRightOnRectangleIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button> : null
                                                    }
                                                </NavLink>
                                            </>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            <div className="relative mr-5 mb-5">
                                <div className="absolute inset-y-0 top-0 right-0 items-center sm:hidden ">
                                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                            <div className='lg:block hidden'>
                                <div className="flex items-end">
                                    <img
                                        className="block h-16 w-auto"
                                        src="./Buho.png"
                                        alt="Your Company"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden gris1">
                        <div className="space-y-1 px-2 pt-2 pb-3">
                            <div className='flex flex-row justify-center items-center flex-wrap'>
                                {getNavData().map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className='px-3 py-2'
                                    >
                                        <p className='text-sky-500 text-base font-bold '> {item.name} </p>
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
