import { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'

interface Props {
    length?: string
}

export const UserName = ({ length }: Props) => {

    const [name, setName] = useState<string>("")


    useEffect(() => {
        let userData = localStorage.getItem('userData');
        if (userData) {
            let name = JSON.parse(userData).name
            setName(name);
        }
    }, [])

    return (
        <div className={`flex flex-row items-end py-5 ${length ? length : 'w-8/12'} `} >
            <FaUserCircle className='text-4xl blue1color ' /> <h1 className='blue1color font-bold px-3'> {name}</h1>
        </div>
    )
}
