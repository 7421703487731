import { validateStatus } from '../utils/validateStatus';

export const sortData = (data:any, status:string) => {

    data.forEach((e:any, i:number) => {
        data[i]['status'] = validateStatus(e.dateUntil, false)
    });

    return data.filter((e:any) => e.status === status)
}
