import { NewCardModal } from "./NewCardModal";
import { useEffect, useState } from 'react';
import { server } from '../utils/connection';
import userData from '../utils/getUserData';
import { alert } from '../utils/alert';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Modal } from "./Modal";

/* interface Props {
    name: string;
    type: string;
    lastNumbers: string;
    imageUrl: string;
} */

export const Cards = ({data}:any) => {

    const [dataCard, setDataCard] = useState<any>([])
    const [visible, setVisible] = useState(false)

    const load = async () => {
        try {
            let response = await server(true).get(`/payment/stripe/get-cards/${userData().id}`);
            if (!response && response.data.stripeResponse.length === 0) {
                return;
            }
            data(response.data.stripeResponse)
            setDataCard(response.data.stripeResponse)
        } catch (error) {
            alert('Hubo un error al traer tus tarjetas.', 'error', '#c35353', 2000)
        }
    }

    const deleteCard = async (id: string) => {
        const MySwal = withReactContent(Swal)

        const swalWithBootstrapButtons = MySwal.mixin({
            customClass: {
                confirmButton: 'btn btn-success #3085d6',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Descartar tarjeta',
            text: "¿Deseas continuar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, descartar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await server(true).delete(`/payment/stripe/delete-card`, {
                        data: {
                            "userId": userData().id,
                            "token": "1",
                            "cardId": id
                        }
                    });
                    load()
                    alert('Tarjeta borrada correctamente!', 'success', '#73A3D5', 1500) 
                } catch (error) {
                    alert('Hubo un error al borrar tu tarjeta.', 'error', '#c35353', 2000)
                }
            }
        })

    }

    useEffect(() => {
        // eslint-disable-next-line
        load()
    }, [dataCard, visible])


    return (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {dataCard.map((e: any, k: number) => (
                <li key={k} className="col-span-2 lg:w-80 divide-gray-200 rounded-lg bg-white shadow">
                    <div className="flex w-full items-center justify-between space-x-6 px-6 py-3">
                        <img className="h-10 w-10" src={`./${e.brand}.png`} alt="" />
                        <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-bold">{e.name}</h3>
                            </div>
                            <p className="mt-1 truncate text-sm text-black-500">Tarjeta de {e.funding === 'credit' ? 'Credito' : 'Debito'}</p>
                            <p className="mt-1 truncate text-sm text-black-500">Con Terminación &#x2022;&#x2022;&#x2022;&#x2022;{e.last4}</p>
                        </div>
                    </div>
                    <div className='flex w-full justify-end px-2 mb-2 cursor-pointer' onClick={() => deleteCard(e.id)}>
                        <p className='text-indigo-500 px-1 text-xs'>Descartar</p>
                    </div>
                </li>
            ))}
            <li className="col-span-2 lg:w-80 divide-gray-200 rounded-lg bg-white shadow cursor-pointer" onClick={() => {setVisible(true)}}>
                <div className="flex w-full items-center justify-between space-x-6 px-6 py-3">
                    <img className="h-10 w-15" src="./save.png" alt="" />
                    <div className="flex-1 truncate">
                        <p className="mt-1 truncate text-sm text-black-500">Agregar tarjeta</p>
                        <p className="mt-1 truncate text-sm text-black-500">de crédito o débito</p>
                    </div>
                </div>
            </li>
            <Modal isVisible={visible} onClose={() => setVisible(false)} size={''}>
                <NewCardModal load={load} />
            </Modal>
        </ul>
    )
}
