import { Title } from './Title';
import { MdAddToDrive, MdPictureAsPdf } from "react-icons/md";
import { FaLongArrowAltDown } from 'react-icons/fa';
import { server } from '../utils/connection';
import { useEffect, useState } from 'react';

export const NewReclamation = () => {

    const [companies, setCompanies] = useState([])
    const [policies, setPolicies] = useState([])
    const [guide, setGuide] = useState<any>([])
    const [disabledSelect, setDisabledSelect] = useState(true)
    const [showButtons, setShowButtons] = useState(false)



    useEffect(() => {
        getData()
    }, [])

    const getPolice = (policie: any) => {
        setPolicies(JSON.parse(policie))
        setDisabledSelect(false)
    }

    const getData = async () => {
        try {
            let companies = await server(true).get('/pdf-bot/companies-policies');
            console.log(companies)
            setCompanies(companies.data)

        } catch (error) {
            /* alert('Enviado correctamente!', 'success', '#73A3D5') */
        }
    }

    const getGuide = (data: any) => {
        setShowButtons(true)
        setGuide([JSON.parse(data)])
    }

    return (
        <div className='flex flex-col py-10 bg-white'>

            <div className='lg:flex lg:flex-col lg:w-8/12 w-full self-center'>

                <Title title='TODO LO QUE NECESITAS PARA LA RECLAMACIÓN DE SINIESTROS' />

                <div className="flex flex-col">

                    <div className='flex lg:justify-start justify-center'>
                        <p className='font-bold py-7'>&#x2022;Checklist</p>
                    </div>

                    <div className="flex items-center flex-row w-full py-2">
                        <div className='flex bg-white lg:w-1/12 w-4/12 lg:justify-start justify-center'>
                            <span className='text-slate-400'>Compañia</span>
                        </div>
                        <select onChange={(e) => { getPolice(e.target.value) }} className='gris3 lg:w-3/12 w-7/12 h-10 px-2 border-none'>
                            <option value="antiguo">Elige una compañia...</option>
                            {
                                companies.map((e: any, k) => <option key={k} value={JSON.stringify(e.policiesType)}>{e["company"].companyShowName}</option>)
                            }
                        </select>
                    </div>

                    <div className="flex items-center flex-row w-full py-2">
                        <div className='flex bg-white lg:w-1/12 w-4/12 lg:justify-start justify-center'>
                            <span className='text-slate-400'>Ramo</span>
                        </div>
                        <select onChange={(e) => { getGuide(e.target.value) }} className='gris3 lg:w-3/12 w-7/12 h-10 px-2 border-none' disabled={disabledSelect}>
                            {
                                policies.length > 0 ? policies.map((e: any, i) => <option key={i} value={JSON.stringify(e)}>{e.policyTypeName}</option>) : ''
                            }
                        </select>
                    </div>

                    <div className="flex items-center flex-row w-full py-2">

                        <div className='flex bg-white lg:w-1/12 w-4/12 lg:justify-start justify-center'>
                            <span className='text-slate-400'>Guía</span>
                        </div>

                        <div className=' inside px-5 py-5 lg:w-3/12 w-7/12'>

                            {
                                guide.length > 0 ? guide.map((e: any) =>
                                    <div className='flex flex-row'>
                                        <div className='w-auto'>
                                            <MdPictureAsPdf className='text-red-500 text-2xl' />
                                        </div>
                                        <div className='flex px-5 w-full'>
                                            <p className='font-bold text-gray-500'> {e.guide} </p>
                                        </div>
                                    </div>
                                ) : <div className='flex flex-row'>
                                    <div className='w-auto'>
                                        <MdPictureAsPdf className='text-red-500 text-2xl' />
                                    </div>
                                    <div className='flex px-5 w-full'>
                                        <p className='font-bold text-gray-500'> Selecciona un ramo... </p>
                                    </div>
                                </div>
                            }


                            {
                                showButtons ? 
                                <div className='flex flex-row'>
                                    <div className='gris7bg rounded-md px-1 py-1 mr-3 ml-11 mt-2'>
                                        <FaLongArrowAltDown className='text-white text-2xl' />
                                    </div>
                                    <div className='gris7bg rounded-md px-1 py-1 mt-2'>
                                        <MdAddToDrive className='text-white text-2xl' />
                                    </div>
                                </div> : ''
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

