import { useEffect, useState } from "react";
import formatDate from "../utils/formatDate"
import { BsFillCheckCircleFill, BsFillDashCircleFill, BsFillEyeFill } from "react-icons/bs";
import toBase64 from "../utils/toBase64";

interface Props {
    data: any
}

export const Table = ({ data }: Props) => {
    const [propData, setPropData] = useState([])

    const downloadPdf = (pdf: string) => {
        let byte = toBase64(pdf);
        let blob = new Blob([byte], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob), "_blank");
    }

    const tableBody = () => {
        setPropData(data)
        return (
            propData.map((e: any, i: number) => (
                <tr key={e.email} className={i % 2 === 1 ? 'bg-gray-100' : 'bg-gray-200'}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        {e.razonSocial}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{formatDate(e.creationDt)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">$ {e.amount}</td>
                    <td className="flex justify-center items-center space-x-2 py-4 text-gray-500">{e.emailSentDate ? <><p>{formatDate(e.emailSentDate)} </p><BsFillCheckCircleFill /></> : <><p>PENDIENTE</p><BsFillDashCircleFill /></>}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{e.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-lg text-gray-500 flex justify-center items-center cursor-pointer" onClick={() => downloadPdf(e.fileData)}><p><BsFillEyeFill /></p></td>
                </tr>
            ))
        )
    }
/* eslint-disable */
    useEffect(() => {
        // eslint-disable-next-line
        tableBody()
    }, [propData])
/* eslint-enable */
    return (
        <div className="px-4 sm:px-6 lg:px-8 mb-5">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full ">
                                <thead className="bg-gray-150">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 text-center">
                                            Razón Social
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                            Fecha de pago
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                            Importe
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                            Fecha de envio de correo
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                            Correo
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                                            Visualizar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {
                                        tableBody()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
