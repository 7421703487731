import axios from 'axios';
import { headerAuth, headers } from './constants';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8084/api/v1/';

const server = (auth:boolean) => {
    const request = axios.create({
        baseURL: API_URL,
        headers: auth ? headerAuth : headers
    })
    return request;
}


export {server};