import React from 'react'

interface props {
    status: boolean,
    text: string,
    class?: string
}

export const SubmitButton = ({ ...props } : props) => {
    return (
        <button type="submit" className={props.class ? props.class : "naranja1 text-white px-6 rounded-md w-auto py-3 font-bold flex items-center justify-center"} disabled={props.status}>
            {
                props.status ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg> : props.text
            }
        </button>
    )
}
