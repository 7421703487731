import { BarComponent } from '../../components/BarComponent';
import { BarSimpleComponent } from '../../components/BarSimpleComponent';

const data = [
  {
    month: '1',
    amount: 4000,
    year: 2020,
  },
  {
    month: '2',
    amount: 3000,
    year: 2023,
  },
  {
    month: '3',
    amount: 2000,
    year: 2022,
  },
  {
    month: '4',
    amount: 1500,
    year: 2021,
  },
  {
    month: '5',
    amount: 7800,
    year: 2021,
  },
  {
    month: '4',
    amount: 900,
    year: 2023,
  },
  {
    month: '7',
    amount:  500,
    year: 2022,
  },
  {
    month: '1',
    amount: 7800,
    year: 2023,
  },
  {
    month: '9',
    amount: 9800,
    year: 2020,
  },
  {
    month: '5',
    amount: 3100,
    year: 2014,
  },
  {
    month: '11',
    amount: 600,
    year: 2022,
  },
  {
    month: '3',
    amount: 2600,
    year: 2023,
  },
];

export const Reportes = () => {
  /* const [dataPolizas, setDataPolizas] = useState([])
  const [users, setUsers] = useState([]) */

  /* const getPolices = async () => {
    try {
      let request = await server(true).get(`/payment/stripe/get-amount-charges`)
      setDataPolizas(request.data.payments)
    } catch (error) {
      alert('Hubo un error al traer los datos de la tabla cargos.', 'error', '#c35353', 2000)
    }
  }

  const getUsers = async () => {
    try {
      let request = await server(true).get(`/users/get-amount-register-users`)
      setUsers(request.data)
    } catch (error) {
      alert('Hubo un error al traer los datos de la tabla usuarios.', 'error', '#c35353', 2000)
      console.log(error)
    }
  }

  useEffect(() => {
    getPolices()
    getUsers()
  }, []) */


  return (
    <div className='flex flex-col justify-center py-10'>
      <div className='mt-20'>
        <BarComponent data={data} title={<p>Tabulador <br /> Económico</p>} name={true} />
      </div>
      <div className='mt-10'>
        <BarSimpleComponent data={data} title={<p>Tabulador <br /> Pólizas</p>} name={false} />
      </div>
      <div className='mt-10 mb-10'>
        <BarSimpleComponent data={data} title={<p>Tabulador <br /> Clientes</p>} name={false} />
      </div>
    </div>
  )
}
