
export const PhoneCard = () => {
    return (
        <div className='flex flex-col px-10 mt-10 lg:mt-0 lg:ml-5 justify-center h-64 md:px-10 blue1'>
            <div className='place-self-center'>
                <p className='text-white font-bold py-4'>Llama a uno de nuestros <br />agentes en caso de que<br /> necesites apoyo</p>
                <div className="h-1 naranja1 w-32" />
                <ul className='py-4 space-y-4'>
                    <li className='text-white font-light'>(33) • 3630 • 0319</li>
                    <li className='text-white font-light'>(33) • 3616 • 2119</li>
                    <li className='text-white font-light'>(33) • 3616 • 7046</li>
                </ul>
            </div>
        </div>
    )
}
