import { useState, useEffect } from 'react';
import userData from '../utils/getUserData';
import { EditModal } from './EditModal';
import { Modal } from './Modal';

export const PersonalData = () => {
    const [change, setChange] = useState(false)
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState<any>(userData())
    
    const updated = () => {
        setChange(true)
    }

    useEffect(() => {
        setData(userData())
        setChange(false)
    }, [change])
    

    return (
        <div className='lg:flex lg:flex-col w-full h-[520px] p-5 '>
            <div className="flex w-full justify-end px-12 lg:pt-0 pt-4">
                <p className="morado2color cursor-pointer" onClick={() => setVisible(true)}>
                    Editar datos
                </p>
            </div>
            <div className="flex w-auto items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>Nombre completo</p>
                </div>
                <div className='w-auto'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.name} disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>Fecha de nacimiento</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.birthDate ? data?.birthDate : "-"} disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>Correo</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.contactDto.email ? data?.contactDto.email : "-"} disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>No. de celular</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.contactDto.cellPhone ? data?.contactDto.cellPhone : "-"} disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>No. de teléfono fijo</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.contactDto.homePhone ? data?.contactDto.homePhone : "-"}  disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>Dirección</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" value={data?.contactDto?.address ? data?.contactDto?.address : "-"} disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>RFC</p>
                </div>
                <div className='w-7/12'>
                    <input placeholder={"(Opcional)"} value={data?.rfc ? data?.rfc : "-"} className="gris1 block w-full px-4" disabled={true} />
                </div>
            </div>
            <div className="flex w-full items-center py-2">
                <div className='w-4/12 px-6'>
                    <p>Empresa</p>
                </div>
                <div className='w-7/12'>
                    <input className="gris1 h-8 px-4 w-full" placeholder={"(Opcional)"} value={data?.corporation ? data?.corporation : "-"} disabled={true} />
                </div>
            </div>

            <Modal isVisible={visible} onClose={()=>{setVisible(false)}} size={'w-auto'}>
                <EditModal updated={updated}  />
            </Modal>
        </div>
    )
}
