import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../components/MyTextInput';
import MyTextArea from './MyTextArea';
import { server } from '../utils/connection';
import userData from '../utils/getUserData';
import { useState } from 'react';
import { alert } from '../utils/alert';
import { SubmitButton } from './SubmitButton';

export const ContactPage = () => {
    const [block, setBlock] = useState(false)

    return (
        <div>
            <Formik
                initialValues={{
                    asunto: '',
                    mensaje: '',
                }}
                onSubmit={async (values) => {
                    setBlock(true)
                    try {
                        await server(true).post('/send',
                            {
                                "requestType": "EMAIL",
                                "mailDetailsEntity": {
                                    "sender": "ac-seguros-dev@luyusteahouse.com",
                                    "recipient": "panduro.sergio1@gmail.com",
                                    "CC": "panduro.sergio1@gmail.com",
                                    "Bcc": "panduro.sergio1@gmail.com",
                                    "subject": "CONTACTO CLIENTE",
                                    "body": `USER:${userData().name} <br/>
                                                ASUNTO: ${values.asunto} <br/>
                                                MENSAJE: ${values.mensaje}<br/>`,
                                    "signature": "Enviado desde contacto"
                                }
                            });
                        alert('¡Mensaje enviado!', 'success', '#73A3D5')
                        setBlock(false)
                    } catch (error) {
                        setBlock(false)
                        alert('Hubo un error al enviar el mensaje.', 'error', '#c35353', 2000)
                    }
                }}
                validationSchema={
                    Yup.object({
                        asunto: Yup.string()
                            .min(15, 'Debe tener 15 caracteres minimo')
                            .required('Este campo es requerido.'),
                        mensaje: Yup.string()
                            .required('Este campo es requerido.'),
                    })
                }
            >
                {
                    (formik) => (
                        <Form className="flex flex-col gris1 p-6 space-y-4">
                            <div>
                                <MyTextInput label='Asunto' classic={true} name='asunto' class='block w-full  py-3 px-4 text-gray-900 bg-white' />
                            </div>
                            <div>
                                <MyTextArea label='Escribe tu mensaje' classic={true} name='mensaje' class='block w-full border-none py-3 px-4 text-gray-900 bg-white' rows={4} cols={4} />
                            </div>
                            <div className='self-end mt-6'>
                                <SubmitButton status={block} text={'ENVIAR'} />
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}
