import { useField } from 'formik'

interface Props {
    label: string;
    name: string;
    type?: 'text' | 'email' | 'password' | 'checkbox' | 'number';
    placeholder?: string;
    [x: string]: any;
    class?: string;
    disabled?: boolean;
    classic?: boolean
}

const classes = {
    default: "block w-full border-gray-300 py-3 px-4 text-gray-900 gris3 focus:border-indigo-500 focus:ring-indigo-500"
}

export default function MyTextInput({ label, ...props }: Props) {
    const [field, meta] = useField(props)
    return (
        <>
            <div className='grid grid-col-1'>
                <label className={`${props.classic ? 'gris8' : ''}`} htmlFor={props.id || props.name}> {label} </label>
                <input className={props.class ? props.class : classes.default} {...field} {...props} />
                {
                meta.touched && meta.error && (
                    <div>
                        <span className="text-red-600 font-bold"> {meta.error} </span>
                    </div>
                )
            }
            </div>
            
        </>

    )
}
