import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { BarControllers } from './BarControllers';
import { UserName } from './UserName';
import { useState } from 'react';

interface Props {
  data: any,
  title: any
  name?: boolean
}

export const BarComponent = ({ data, title, name }: Props) => {
  const [savedData] = useState(data)
  /* const [dataBar, setDataBar] = useState(data) */

  /* const barElement = (data: any) => {
      data.forEach((e, i) => {
          const date = new Date();
          date.setMonth(e.month - 1);
          let result = date.toLocaleString('es-MX', { month: 'short' })
          data[i]['monthName'] = `${result} - ${String(e.year).slice(-2)}`
      })
      return data
  } */

  /* const random_hex_color_code = () => {
      let n = (Math.random() * 0xfffff * 1000000).toString(16);
      return '#' + n.slice(0, 6);
  }; */

  const filter = (type: string) => {
      const today = new Date()
      const tempData = savedData
      let newData = []

      switch (type) {
          case "6M":
              let sixMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 12));
              tempData.forEach(element => {
                  let validateDate = new Date(`1-${element.month}-${element.year}`)
                  if (validateDate > sixMonthsAgo && validateDate < today) {
                      newData.push(element)
                  } 
              });
              /* setDataBar(newData) */
              break;
          case "1A":
              let oneYearAgo =  new Date(new Date().setFullYear(new Date().getFullYear() - 2));
              tempData.forEach(element => {
                  let validateDate = new Date(`1-${element.month}-${element.year}`)
                  if (validateDate > oneYearAgo && validateDate < today) {
                      newData.push(element)
                  } 
              });
              console.log(newData)
              /* setDataBar(newData) */
              break;
          case "5A":
              let fiveYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
              tempData.forEach(element => {
                  let validateDate = new Date(`1-${element.month}-${element.year}`)
                  if (validateDate > fiveYearsAgo && validateDate < today) {
                      newData.push(element)
                  } 
              });
              /* setDataBar(newData) */
              break;
          case "Todo":
              /* setDataBar(savedData) */
              break;
          default:
              break;
      }
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col w-full self-center'>
        <div className='flex flex-row w-8/12 self-center'>
          <div className='w-3/12'>
            {name ? <UserName length={"w-full"} /> : ""}
            <BarControllers showControllers={true} title={title} />
          </div>
          <div className={`flex flex-col w-9/12 gris1 ml-5  justify-start py-5`}>
            <div className='flex flex-row w-full justify-center self-center'>
              <div className='flex self-start p-2 m-2 gris4 w-5/12'>
                <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`}>Primas</p>
                <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`}>Comisiones</p>
                <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`}>Bono personal</p>
              </div>
              <div className='flex flex-col w-auto self-center'>
                <div className='flex flex-row p-2 justify-center gris4 m-2'>
                  <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("6M") }} >6M</p>
                  <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("1A") }} >1A</p>
                  <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("5A") }} >5A</p>
                  <p className={`px-2 bg-white ml-3 mr-3 p-1 cursor-pointer`} onClick={() => { filter("Todo") }} >Todo</p>
                </div>
                <div className='flex flex-row items-center justify-center'>
                  <div className='flex flex-row p-2 gris4 m-2'>
                    <p className={`px-2 bg-white m-1 p-1 cursor-pointer`}>Mx</p>
                    <p className={`px-2 bg-white m-1 p-1 cursor-pointer`}>USD</p>
                  </div>
                  <div className='flex flex-row p-2 gris4 m-2'>
                    <p className={`px-2 bg-white m-1 p-1 cursor-pointer`}>Millones</p>
                    <p className={`px-2 bg-white m-1 p-1 cursor-pointer`}>Miles</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='self-center'>
              <BarChart
                width={800}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthName" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="amount" fill="#8884d8" />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row w-8/12 self-center'>
        <div className='flex flex-row w-full justify-end'>
          <button className='naranja1 text-white font-bold p-3 rounded-md mt-3'>COMPARTIR</button>
          <button className='naranja1 text-white font-bold p-3 rounded-md ml-3 mt-3'>DESCARGAR REPORTE</button>
        </div>
      </div>
    </div>
  )
}
