import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { server } from '../utils/connection';
import userData from '../utils/getUserData';
import MyDateInput from './MyDateInput';
import MyTextArea from './MyTextArea';
import MyTextInput from "./MyTextInput"
import { SubmitButton } from './SubmitButton';
import { alert } from '../utils/alert';

export const EditModal = ({ updated, user = {} }: any) => {
    const [active, setActive] = useState(false)

    return (
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current" >
            <Formik
                initialValues={!user ? {
                    name: userData()?.name,
                    birthDate: userData()?.birthDate,
                    email: userData()?.contactDto?.email,
                    cellPhone: userData()?.contactDto?.cellPhone,
                    homePhone: userData()?.contactDto?.homePhone,
                    adress: userData()?.addressDto?.address,
                    rfc: userData()?.rfc,
                    corp: userData()?.corporation,

                } : {
                    name: user?.name,
                    birthDate: user?.birthDate,
                    email: user?.contactDto?.email,
                    cellPhone: user?.contactDto?.cellPhone,
                    homePhone: user?.contactDto?.homePhone,
                    adress: user?.addressDto?.address,
                    rfc: user?.rfc,
                    corp: user?.corporation
                }}
                onSubmit={async (values) => {
                    setActive(true)
                    try {
                        await server(true).post(`/users/${!user ? userData()?.id : user.id}`,
                            {
                                "id": !user ? userData()?.id : user.id,
                                "username": !user ? userData()?.username : user.username,
                                "name": values.name,
                                "email": values.email,
                                "birthDate": values.birthDate,
                                "rfc": values.rfc,
                                "corporation": values.corp,
                                "curp": !user ? userData()?.curp : user.curp,
                                "cellPhone": values.cellPhone,
                                "homePhone": values.homePhone,
                                "address": values.adress,
                                "gender": !user ? userData()?.gender : user.gender,
                                "enabled": true
                            })
                        const getUserData = await server(true).get(`/users/id/${!user ? userData()?.id : user.id}`)
                        localStorage.setItem("userData", JSON.stringify(getUserData.data))
                        updated()
                        alert('Datos actualizados!', 'success', '#73A3D5')
                        setActive(false)
                    } catch (error) {
                        setActive(false)
                        alert('Hubo un error al actualizar tus datos.', 'error', '#c35353', 2000)
                    }
                }}
                validationSchema={
                    Yup.object({
                        name: Yup.string().nullable()
                            .min(5, 'Debe tener 5 minimo caracteres')
                            .required('Campo requerido'),
                        birthDate: Yup.string().nullable()
                            .min(5, 'Debe tener 5 minimo caracteres')
                            .required('Campo requerido'),
                        email: Yup.string().nullable()
                            .min(5, 'Debe tener 5 minimo caracteres')
                            .required('Campo requerido'),
                        cellPhone: Yup.string().nullable()
                            .min(5, 'Debe tener 5 minimo caracteres')
                            .required('Campo requerido'),
                        homePhone: Yup.string().nullable()
                            .min(5, 'Debe tener 5 minimo caracteres')
                            .required('Campo requerido'),
                        rfc: Yup.string().nullable()
                            .min(5, 'Debe tener 5 caracteres minimo'),
                        corp: Yup.string().nullable()
                            .min(5, 'Debe tener 5 caracteres minimo')
                    })
                }
            >
                <Form>
                    <div className='w-full blue1 p-3 px-8'>
                        <h1 className='text-white font-bold'>EDITA TUS DATOS</h1>
                    </div>

                    <div className="modal-body flex lg:flex-row flex-col relative p-4">
                        <div className='lg:flex flex-col lg:w-6/12 p-6'>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-5/12 lg:px-6 '>
                                    <p>Nombre completo</p>
                                </div>
                                <div className='lg:w-7/12 '>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="name" />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-5/12 lg:px-6'>
                                    <p>Fecha de nacimiento</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyDateInput label='' class="gris1 h-8 px-4 border-none" name="birthDate" />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-5/12 lg:px-6'>
                                    <p>Correo</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="email" />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-5/12 lg:px-6'>
                                    <p>No. de celular</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="cellPhone" />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-5/12 lg:px-6'>
                                    <p>No. de teléfono fijo</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="homePhone" />
                                </div>
                            </div>
                        </div>

                        <div className='lg:flex flex-col lg:w-6/12 p-6'>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-4/12 lg:px-6'>
                                    <p>Dirección</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextArea placeholder={"(Opcional)"} class="gris1 block w-full px-4" label='' name='adress' rows={4} cols={20} />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-4/12 lg:px-6'>
                                    <p>RFC</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="rfc" />
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col w-full lg:items-center py-2">
                                <div className='lg:w-4/12 lg:px-6'>
                                    <p>Empresa</p>
                                </div>
                                <div className='lg:w-7/12'>
                                    <MyTextInput label='' class="gris1 h-8 px-4" name="corp" placeholder={"(Opcional)"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 mb-5">
                        <SubmitButton status={active} text={'GUARDAR CAMBIOS'} class='naranja1 text-white py-3 rounded-lg flex justify-center lg:w-3/12 w-8/12' />
                    </div>
                </Form>
            </Formik>
        </div>




    )
}

