import { useEffect, useRef, useState } from 'react'
import { Title } from '../../components/Title'
import { UserName } from '../../components/UserName'
import { FaFileDownload, FaSearch, FaTimes } from "react-icons/fa";
import { SearchClient } from '../../components/SearchClient';
import { HiOutlineUpload } from "react-icons/hi";
import { DataHolder } from '../../components/DataHolder';
import { LoadArticle } from '../../components/LoadArticle';
import { Card } from '../../components/Card';
import { NewOldFilters } from '../../components/NewOldFilters';
import { AddPolicie } from '../../components/AddPolicie';
import { server } from '../../utils/connection';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { alert } from '../../utils/alert';
import formatDate from '../../utils/formatDate';
import { Accordion } from 'flowbite-react';
import { Modal } from '../../components/Modal';
import { EditPolicie } from '../../components/EditPolicie';
import { EditModal } from '../../components/EditModal';
import userData from '../../utils/getUserData';
import changeDateFormat from '../../utils/formatDatePost';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const Poliza = () => {
    const [data, setData] = useState<any>([]);
    const [current, setCurrent] = useState<any>([]);
    const [dataPolizas, setDataPolizas] = useState([])
    const [saved, setSaved] = useState([])
    const [article, setArticles] = useState<any>("")
    const [charges, setCharges] = useState<any>([])
    const [cards, setCards] = useState<any>([])
    const [subscriptions, setSubscriptions] = useState<any>([])
    const [visible, setVisible] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)
    const [editedPolicie, setEditedPolicie] = useState<any>([])
    const [editUser, setEditUser] = useState<boolean>(false)


    const cardElement = useRef<HTMLHeadingElement>(null);

    const getCharges = async () => {
        if (current.length === 0) {
            return;
        }
        try {
            let response = await server(true).get(`/payment/stripe/get-charges/${current?.id}`);
            if (response.data.status === 'ERROR') {
                return setCharges([])
            }
            setCharges(response.data.stripeResponse)
        } catch (error) {
            /* alert('Hubo un error al traer tus cargos.', 'error', '#c35353', 2000) */
            setCharges([])
        }
    }

    const getSubs = async () => {
        if (current.length === 0) {
            return;
        }
        try {
            let response = await server(true).get(`/payment/stripe/get-subscriptions/${current?.id}`);
            if (response.data.status === 'ERROR') {
                return setSubscriptions([])
            }
            setSubscriptions(response.data.stripeResponse)
        } catch (error) {
            alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
            setSubscriptions([])
        }
    }

    const deleteSub = async (id: string) => {
        const MySwal = withReactContent(Swal)

        const swalWithBootstrapButtons = MySwal.mixin({
            customClass: {
                confirmButton: 'btn btn-success #3085d6',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Desactivar autopago',
            text: "¿Deseas continuar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, desactivarlo',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await server(true).post(`/payment/stripe/cancel-subscription`, {
                        "subscriptionId": id
                    });
                    alert('El autopago para tu poliza a sido desactivado correctamente.', 'success', '#73A3D5', 1500)
                } catch (error) {
                    alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
                }

            }
        })
    }

    const getData = (data: any) => {
        if (data.length > 0) {
            return setDataPolizas(data)
        }
        return setDataPolizas([])
    }

    const changeCurrent = (tab: any) => {
        setCurrent(tab)
        getArticle(tab)
    }

    const refresh = () => {
        getArticle(current)
    }

    const getArticle = async (current: any) => {
        if (current.length === 0) {
            return setArticles(undefined);
        }
        try {
            let request = await server(true).get(`/users/${current?.id}/article`)
            setArticles(request.data)
        } catch (error) {
            return setArticles(undefined)
        }
    }

    const closeTab = (removeIndex: any) => {
        let usersData = localStorage.getItem('users')
        if (usersData) {
            let newUserList = JSON.parse(usersData)?.filter((value: any, i: number) => i !== removeIndex)
            if (newUserList.length === 0) {
                setCurrent([])
            }
            localStorage.setItem("users", JSON.stringify(newUserList))
            setData(newUserList)
            search()
        }
        setData([])
        search()
    }

    const search = () => {
        let usersData = localStorage.getItem('users')
        if (usersData) {
            const users = JSON.parse(usersData)
            if (users.length > 0) {
                setData(users)
                getArticle(users[0])
                return setCurrent(users[0])
            }
            getArticle(users[0])
            return setData("")
        }
    }

    const getPolices = async () => {
        if (current.length === 0) {
            return;
        }
        try {
            let request = await server(true).post(`/pdf-bot/get-policy`, {
                "clientId": current.id,
            })
            if (request.data.status === 'ERROR') {
                setSaved([])
                return setDataPolizas([])
            }
            setSaved(request.data.policies)
            setDataPolizas(request.data.policies)
        } catch (error) {
            return error
        }
    }

    const getCards = async () => {
        if (![current].length) {
            return;
        }
        try {
            let request = await server(true).get(`/payment/stripe/get-cards/${[current][0].id}`)
            if (request.data.stripeResponse && !request.data.stripeResponse.length) {
                return setCards([])
            }
            setCards(request.data.stripeResponse)
        } catch (error) {
            return error;
        }
    }

    const editPolicie = (e) => {
        let policie = dataPolizas.filter((element) => element.policyNumber === e)
        setEditedPolicie(policie)
        setEdit(true)
    }

    const reloadPolicies = async () => {
        setVisible(false)
        getPolices()
    }

    const updated = () => {
        setEditUser(false)
    }

    useEffect(() => {
        search()
    }, [])
/* eslint-disable */
    useEffect(() => {
        // eslint-disable-next-line
        getPolices()
        getCharges()
        getSubs()
        getCards()
    }, [current])
/* eslint-enable */

    return (
        <div className='flex flex-col justify-center py-10'>
            <div className='lg:flex lg:flex-row lg:w-10/12 w-11/12 self-center'>
                <UserName length='w-9/12' />
            </div>
            <div className='lg:flex lg:flex-row lg:w-10/12 w-11/12 self-center'>
                <div className='gris1 lg:w-6/12 sm:w-full'>
                    <Title Icon={FaSearch} length="lg:w-full" title="BUSCAR PÓLIZA/CLIENTE" />
                    <SearchClient search={search} />
                </div>
                <div className='w-10'></div>
                <div className='morado2 lg:w-6/12 sm:w-full'>
                    <div className={"flex flex-row lg:w-full items-center morado3bg md:w-3/6 sm:w-full justify-center"}>
                        <HiOutlineUpload className="text-white text-xl mr-3" /><h1 className={'text-white font-bold py-2'}>SUBIR PÓLIZA PDF</h1>
                    </div>
                    <div className='lg:flex lg:flex-row sm:flex-col sm:justify-center p-12'>
                        <button className='naranja1 text-white w-full lg:w-auto px-5 py-2 h-11 rounded-md font-bold' onClick={() => {
                            if (current.length !== 0) {
                                return setVisible(true)
                            }
                            return alert('Primero elige un cliente.', 'error', '#c35353', 2000)
                        }}>SUBIR PÓLIZA</button>
                    </div>
                </div>
            </div>

            <div className='flex flex-row w-10/12 self-center mt-10'>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                            {
                                data.length > 0 ? data.map((tab, i) => (
                                    <div
                                        key={tab.name}
                                        className={classNames(
                                            tab.name === current.name ? 'gris1' : 'border-transparent text-gray-500 hover:text-gray-700 gris9bg hover:border-gray-300',
                                            'flex group items-center w-full '
                                        )}
                                        aria-current={tab.currentUser ? 'page' : undefined}>
                                        <div
                                            className='w-9/12 p-4 px-8 text-right cursor-pointer'
                                            onClick={() => {
                                                changeCurrent(tab)
                                            }}
                                        >
                                            <span className='truncate'> {tab.name} </span>
                                        </div>
                                        <div onClick={() => { closeTab(i) }} className='flex justify-center w-3/12 py-4 px-6 cursor-pointer'>
                                            <span>
                                                <FaTimes
                                                    className={classNames(
                                                        tab.currentUser ? 'text-gray-500 hover:text-black' : 'text-gray-400 group-hover:text-gray-500',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                )) : ''
                            }
                        </nav>
                    </div>
                </div>
            </div>

            {data.length > 0 ? <div className='flex flex-row w-10/12 self-center'>
                <Accordion className='w-full rounded-none gris1' alwaysOpen={true}>
                    <Accordion.Panel className='rounded-none gris1'>

                        <Accordion.Title className='gris1 rounded-none'>
                            <div className=' flex items-center justify-center space-x-5 w-full' >
                                <p className='text-xl font-bold'>DATOS DEL TITULAR</p>
                                {userData().roles[0] === "ROLE_SUPER_ADMIN" || userData().roles[0] === "ROLE_ADMIN" ? <p className='morado3 cursor-pointer' onClick={() => { setEditUser(true) }}> Editar datos </p> : <></>}
                            </div>
                        </Accordion.Title>

                        <Accordion.Content className='gris1 rounded-none border-none'>
                            <div className='flex w-full justify-end'>
                                <h2 className='gris8'>(última modificación {changeDateFormat(current.updatedDt)})</h2>
                            </div>
                            {[current].map((e,i) => (
                                <div key={i} className="grid grid-cols-1 lg:grid-cols-8 py-4 gris1">
                                    <div className='lg:col-span-6'>
                                        <DataHolder
                                            name={e.name}
                                            birthDate={e.birthDate}
                                            email={e.contactDto?.email}
                                            cellPhone={e.contactDto?.cellPhone}
                                            homePhone={e.contactDto?.homePhone}
                                            adress={e.addressDto?.address}
                                            rfc={e.rfc}
                                            corp={e.corporation} />
                                    </div>
                                    <div className='col-span-2'>
                                        <LoadArticle article={article} current={current} refresh={refresh} />
                                    </div>
                                </div>
                            ))}
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel >
                        <Accordion.Title className='gris1'>
                            <p className='text-xl font-bold'>PÓLIZAS</p>
                        </Accordion.Title>
                        <Accordion.Content className='gris1 border-none'>
                            {
                                <>
                                    <div className='w-full flex items-start justify-start'>
                                        <NewOldFilters getData={getData} justify="justify-start" newData={dataPolizas} save={saved} sendData={true} reference={cardElement} fieldStart={"vigenciaInicio"} fieldEnd={"vigenciaFin"} newVersion={true} />
                                    </div>
                                    <div ref={cardElement} className='flex flex-wrap lg:w-full w-full place-items-center'>
                                        {dataPolizas && dataPolizas.length > 0 ? dataPolizas.map((e) => (
                                            <div className='px-2 py-5'>
                                                <Card
                                                    name={e.name}
                                                    concepto={e.concept}
                                                    ramo={e.type}
                                                    compania={e.company}
                                                    poliza={e.policyNumber}
                                                    vigenciaInicio={e.dateFrom}
                                                    vigenciaFin={e.dateUntil}
                                                    prima={e.totalPremium}
                                                    tipoPrima={e.premiumType}
                                                    pdf={e.fileData}
                                                    edit={editPolicie}
                                                ></Card>
                                            </div>
                                        )) : <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>Usuario sin polizas.</h2></div>
                                        }
                                    </div>
                                </>
                            }
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel className='gris3 rounded-none'>
                        <Accordion.Title className='gris3 rounded-none'>
                            <p className='text-xl font-bold'>DATOS DE PAGOS</p>
                        </Accordion.Title>
                        <Accordion.Content className='gris3 border-none'>
                            {
                                <div className="flex w-full ">
                                    <div className='w-full mt-5'>
                                        <div className='px-6 py-2'>
                                            <h1 className='font-bold text-lg'>DATOS DE TARJETA</h1>
                                        </div>
                                        <div className='bg-white space-x-6 px-12 py-6'>
                                            <div className='flex lg:flex-row flex-col'>
                                                <div className='lg:w-6/12'>
                                                    <h1 className='font-bold'>Nombre de la tarjeta:</h1>
                                                    <p>{[current][0].name}</p>
                                                    <h1 className='font-bold mt-5'>Expira:</h1>
                                                    <h1> {cards && cards.length > 0 ? `${cards[0].expMonth < 10 ? `0${cards[0].expMonth}` : cards[0].expMonth}/${cards[0].expYear}` : '-/-'} </h1>
                                                </div>
                                                <div className='lg:w-6/12 flex flex-col justify-start'>
                                                    <h1 className='font-bold'>Dirección de facturación:</h1>
                                                    <p>
                                                        {
                                                            [current].length > 0 && current.addressDto ?
                                                                `${current.addressDto?.address}, ${current.addressDto?.address2}, ${current.addressDto?.state}, ${current.addressDto?.city}, ${current.addressDto?.country}, CP.${current.addressDto?.zipCode}, ${current.contactDto?.cellPhone}` :
                                                                'Sin información'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <Accordion className='w-full rounded-none border-none' alwaysOpen={true}>
                                                <Accordion.Panel className='rounded-none '>
                                                    <Accordion.Title className='rounded-none border-none gris3'>
                                                        <p className='text-xl font-bold '>PÓLIZAS LIGADAS A LA TARJETA</p>
                                                    </Accordion.Title>
                                                    <Accordion.Content className='gris3 rounded-none border-none'>
                                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                            <thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
                                                                <tr>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    subscriptions && subscriptions.length > 0 ? subscriptions.map((e: any) => (
                                                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                                            <td className="px-6 py-4">
                                                                                <p className='text-black text-base'>&#x2022; No. {e.created}</p>
                                                                            </td>
                                                                            <td className="px-6 py-4">
                                                                                <p className='text-black text-base'>{'modelo'}, {'año'}</p>
                                                                            </td>
                                                                            <td className="px-6 py-4">
                                                                                <p className='text-black text-base'>$ {e.items.data[0].price.unitAmount}.00</p>
                                                                            </td>
                                                                            <td className="px-6 py-4 cursor-pointer">
                                                                                <p className='text-black text-base blue1color' onClick={() => { deleteSub(e.id) }}>Desactivar autopago</p>
                                                                            </td>
                                                                        </tr>
                                                                    )) : <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>Usuario sin suscripciones.</h2></div>}
                                                            </tbody>
                                                        </table>
                                                    </Accordion.Content>
                                                </Accordion.Panel>
                                                <Accordion.Panel className='rounded-none '>
                                                    <Accordion.Title className='rounded-none gris3'>
                                                        <p className='text-xl font-bold'>HISTORIAL DE PAGOS</p>
                                                    </Accordion.Title>
                                                    <Accordion.Content className='gris3 rounded-none'>
                                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                            <thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
                                                                <tr>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    charges && charges.length > 0 ? charges.map((e: any) => (
                                                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                                            <td className="px-6 py-4">
                                                                                <div className='lg:col-span-2 col-span-3'>
                                                                                    <p className='text-gray-400'> {formatDate(e.created * 1000)} </p>
                                                                                    <p> Poliza-{e.receiptNumber ? e.receiptNumber : 'PENDIENTE'} </p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-6 py-4">
                                                                                <p className='text-black text-base'>Pagada con &#x2022;&#x2022;&#x2022;&#x2022;{e.paymentMethodDetails.card.last4}</p>
                                                                            </td>
                                                                            <td className="px-6 py-4">
                                                                                <div className='flex flex-col'>
                                                                                    <a href={e.receiptUrl} target="_blank" rel="noreferrer"><FaFileDownload className="text-3xl text-indigo-500" /></a>
                                                                                    <p className='text-indigo-500 text-xs'>Factura</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-6 py-4 cursor-pointer">
                                                                                <p className='text-gray-500'>$ {e.amount}.00 </p>
                                                                            </td>
                                                                        </tr>
                                                                    )) : <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>Usuario sin cargos.</h2></div>}
                                                            </tbody>
                                                        </table>
                                                    </Accordion.Content>
                                                </Accordion.Panel>
                                            </Accordion>
                                        </div>

                                    </div>
                                </div>
                            }
                        </Accordion.Content>
                    </Accordion.Panel>

                </Accordion>
            </div> : ''}
            <Modal isVisible={visible} onClose={() => { setVisible(false) }} >
                <AddPolicie current={current} reload={reloadPolicies} />
            </Modal>

            <Modal isVisible={editUser} onClose={() => { setEditUser(false) }}  >
                <EditModal updated={updated} user={current} />
            </Modal>

            <Modal isVisible={edit} onClose={() => { setEdit(false) }}  >
                <EditPolicie current={current} policie={editedPolicie} reload={reloadPolicies} />
            </Modal>
        </div>
    )
}
