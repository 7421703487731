import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { navigationDataUser, navigationDataAdmin, navigationDataLogOut } from '../utils/constants';
import Navbar from '../components/Navbar';
import validateToken from '../utils/validateToken';

export default function Navigation() {

    const [parsedData, setParsedData] = useState<any>();

    const rawData = localStorage.getItem('userData')

    useEffect(() => {
        validateToken()
    }, [])

    const validateTypeOfUser = () => {
        if (rawData) {
            let validate = JSON.parse(rawData)?.roles.some((e: string) => e === "ROLE_ADMIN" || e === "ROLE_SUPER_ADMIN" || e === "ROLE_MANAGER" || e === "ROLE_EDITOR");
            if (validate) {
                return true;
            }
            return false;
        }
    }

    const check = () => {
        getDataRoutes();
        if (rawData) {
            setParsedData(JSON.parse(rawData))
        }
    }

    const getDataRoutes = () => {
        const logged = localStorage.getItem('logged')
        if (logged && logged === "true") {
            if (validateTypeOfUser() === false) {
                return localStorage.setItem('type', 'user')
            } else if (validateTypeOfUser() === true) {
                return localStorage.setItem('type', 'admin')
            } else {
                return localStorage.setItem('type', 'base')
            }
        }
    }

    const routes = () => {
        let type = localStorage.getItem('type')
        if (type === "admin") {
            return (
                navigationDataAdmin.map(({ name, Component, href }: any) => (
                    <Route key={name} path={href} element={<Component userData={parsedData} />}></Route>
                ))
            )
        } else if (type === "user") {
            return (
                navigationDataUser.map(({ name, Component, href }: any) => (
                    <Route key={name} path={href} element={<Component userData={parsedData} />}></Route>
                ))
            )
        } else {
            return (
                navigationDataLogOut.map(({ name, Component, href }: any) => (
                    <Route key={name} path={href} element={<Component userData={parsedData} />}></Route>
                ))
            )
        }
    }

    const baseRoute = () => {
        let logged = localStorage.getItem('logged')
        if (logged === "true") {
            return (
                <Route path="/*" element={<Navigate to={"/poliza"} replace></Navigate>} />
            )
        }
        return <Route path="/*" element={<Navigate to={"/home"} replace></Navigate>} />;
    }

    return (
        <BrowserRouter>
            <div className='flex flex-col h-screen main-layout justify-between'>
                <Navbar check={check} />
                <Routes>
                    {
                        routes()
                    }
                    {
                        baseRoute()
                    }
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    )
}
