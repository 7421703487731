import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import { Table } from './Table';
import { server } from '../utils/connection';
import { alert } from '../utils/alert';

export const List = ({ currentData }) => {
    const [tab, setTab] = useState([]);
    const [current, setCurrent] = useState<any>({});
    const [currentInvoice, setCurrentInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([{ name: 'policies', state: false }, { name: 'recipe', state: false }]);

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const changeCurrent = (tab: any,) => {
        if (current && tab.name === current.name) {
            return;
        }
        tab.currentUser = true
        setCurrent(tab)
        setTab(current =>
            current.map(obj => {
                if (obj.name !== tab.name) {
                    obj.currentUser = false
                }
                return { ...obj, tab };
            }),
        );
        getRecipeById(tab.name)
    }

    const getRecipeById = async (invoice: string) => {
        setLoading(true)
        try {
            let response = await server(true).post(`/payment/invoice/get`, {
                "policyNumber": invoice
            });
            setLoading(false)
            setCurrentInvoice(response.data.invoices)
        } catch (error) {
            alert('Hubo un error al traer las facturas.', 'error', '#c35353', 2000)
            setLoading(false)
            setError(prevItems => {
                const updatedItems = prevItems.map(item => {
                    if (item.name === 'recipe') {
                        return {
                            ...item,
                            /* ['state']: true */
                        };
                    }
                    return item;
                });
                return updatedItems;
            });
        }
    }

    const closeTab = (removeIndex: number) => {
        let invoicesRaw = localStorage.getItem('invoices')
        let invoicesParsed = JSON.parse(invoicesRaw);
        if (invoicesParsed && invoicesParsed.length > 0) {
            let newInvoices = invoicesParsed.filter((invoiceItem: any, i: number) => i !== removeIndex)
            localStorage.setItem("invoices", JSON.stringify(newInvoices))
            setTab(newInvoices)
            return changeCurrent(newInvoices[0])
        }
        /*  setTab([])
         return setCurrent({}) */
    }

    const handleInvoice = (item = { name: '' }) => {
        let prevUsersRaw = localStorage.getItem('invoices')
        let prevUsersParsed = JSON.parse(prevUsersRaw);

        if (!prevUsersParsed || prevUsersParsed.length === 0) {
            localStorage.setItem('invoices', JSON.stringify([item]))
            return searchInvoices()
        }
        let validateDuplicated = prevUsersParsed.some((e) => e.name === item.name)
        if (validateDuplicated) {
            return alert('Este factura ya se encuentra abierta.', 'error', '#c35353', 2000)
        }
        if (prevUsersParsed.length >= 5) {
            return alert('Maximo de pestañas alcanzado, cierra alguna.', 'error', '#c35353', 2000)
        }
        localStorage.setItem('invoices', JSON.stringify([...[item], ...prevUsersParsed]))
        return searchInvoices()
    }

    const searchInvoices = () => {
        let usersData = localStorage.getItem('invoices')
        const invoices = JSON.parse(usersData)
        if (invoices && invoices.length > 0) {
            setTab(invoices)
            getRecipeById(invoices[0].name)
            return setCurrent(invoices[0])
        }
    }
    //* eslint-disable */
    useEffect(() => {
        if (Object.keys(currentData).length !== 0) {
            handleInvoice(currentData)
        }
    }, [currentData])

    useEffect(() => {
        searchInvoices()
    }, [])
    /* eslint-enable */

    const validateError = (name: string) => {
        return error.some((e) => e.name === name && e.state === false)
    }

    return (
        <>
            <div className='flex flex-row w-8/12 self-center mt-10'>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                            {tab.map((tab, i) => (
                                <div
                                    key={tab.name}
                                    className={classNames(
                                        tab.name === current.name ? 'bg-gray-200' : 'border-transparent text-gray-500 hover:text-gray-700 bg-gray-300 hover:border-gray-300',
                                        'flex group items-center w-full '
                                    )}
                                    aria-current={tab.currentUser ? 'page' : undefined}>
                                    <div
                                        className='w-9/12 p-4 px-8 text-right cursor-pointer'
                                        onClick={() => {
                                            changeCurrent(tab)
                                        }}
                                    >
                                        <span className='truncate'> {tab.name} </span>
                                    </div>
                                    <div onClick={() => { closeTab(i) }} className='flex justify-center w-3/12 py-4 px-6 cursor-pointer'>
                                        <span>
                                            <FaTimes
                                                className={classNames(
                                                    tab.currentUser ? 'text-gray-500 hover:text-black' : 'text-gray-400 group-hover:text-gray-500',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            <div className='flex flex-row w-11/12 lg:w-8/12 self-center'>
                <div className="accordion w-full bg-gray-200">

                    {
                        loading ? <div className='flex text-white w-full justify-center'>
                            <div className='bg-gray-300 mb-10 mt-10 p-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div>
                        </div> : ''
                    }
                    {
                        validateError('recipe') ? '' : <div className='mb-10 mt-10 w-full flex items-center justify-center'><div className='blue1 w-8/12 p-5'><h2 className='font-bold text-xl text-white'>Hubo un error al traer las facturas.</h2></div></div>
                    }
                    {
                        currentInvoice.length > 0 ? <Table data={currentInvoice} /> : ''
                    }
                </div>
            </div>
        </>
    )
}

