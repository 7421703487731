
interface Props {
    title: string,
    Icon?: any,
    length?: string
}

export const Title = ({ title, Icon, length }: Props) => {
    return (
        <div className={`${length ? length : "lg:w-96"} flex flex-row items-center blue1 w-full`}>
            {Icon ? <div className="px-5"><Icon className="text-white" /></div> : null} <h1 className={`text-white font-bold py-2 ${Icon ? null : 'px-5'}`} > {title} </h1>
        </div>
    )
}
