import React from 'react'
import { FaMapMarkerAlt, FaPhoneVolume, FaRegEnvelope } from 'react-icons/fa';
import { ContactPage } from '../components/ContactPage';
import { Title } from '../components/Title';
import { UserName } from '../components/UserName';

export const Contacto = () => {
    return (

        <div className='flex flex-col justify-center mt-12 mb-20 w-full'>
            <div className='lg:flex lg:flex-col w-9/12 self-center'>
                <UserName />
                <Title title="CONTACTO" length='lg:w-80'/>
            </div>
            <div className='lg:flex lg:flex-row justify-between items-center w-full'>
                <div className='flex flex-col items-end justify-end flex-1'>
                    <div className='flex flex-row  w-50'>
                        <FaRegEnvelope className='text-2xl mr-2 blue2' />
                        <div className='flex flex-col'>
                            <p className='font-bold text-xl'>Correo</p>
                            <p className='mt-1 '>Envíanos un correo con tus dudas <br /> y nos pondremos en contacto <br/> contigo.  </p>
                            <p className='mt-1'>contacto@acseguros.mx</p>
                        </div>
                    </div>
                    
                    <div className='flex flex-row mt-5  w-72'>
                        <FaPhoneVolume className='text-3xl mr-2 blue2' />
                        <div className='flex flex-col'>
                            <p className='font-bold text-xl'>Teléfonos</p>
                            <p className='mt-1'>O llamanos a nuestras oficinas.  </p>
                            <p className='mt-1'>(33)• 3630 • 0319</p>
                            <p className='mt-1'>(33)• 3630 • 0319</p>
                            <p className='mt-1'>(33)• 3630 • 0319</p>
                        </div>
                    </div>
                </div>
                <div className="flex-1 px-4">
                    <ContactPage />
                </div>
                <div className='flex flex-col flex-1'>
                    <div className='flex flex-col lg:justify-center items-center'>
                        <div className='flex justify-start items-start mb-2 lg:mt-0 mt-4'>
                            <FaMapMarkerAlt className='text-3xl lg:mr-2 blue2' />
                            <p className='font-bold text-2xl'>Ubicación</p>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3732.9837894826182!2d-103.38740539550781!3d20.670238494873047!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae73d8f10a61%3A0x1392a060fa0a5fc4!2sJoaqu%C3%ADn%20Arrieta%20134%2C%20Vallarta%2C%2044690%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1686039329033!5m2!1ses!2smx"
                            width="300" height="300" style={{ border: 0 }} loading="lazy" title="ubicación"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
