import { server } from "./connection";

const getRoles = async() => {
    try {
        let response = await server(true).get('/users/rbac/roles');
        return response.data
    } catch (error) {
        console.log(error)
    }
}

const validateUserRole = async(userRole:Array<string>, permission:string) => {
    let roles = await getRoles();
    let filteredRole = roles.filter((e) => e.role === userRole[0])
    let validate = filteredRole[0].permissions.some((e) => e.permission === permission)
    return validate;
}

export default validateUserRole;