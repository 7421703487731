import { Carousel } from "flowbite-react"

export const CarouselHome = () => {
    return (

        <>
            <div className="h-96 w-full">
                <Carousel indicators={false}>
                    <div className="w-full flex justify-center">
                        <div className="w-9/12">
                            <img className="w-full" src={"./Rectangle1.png"} alt="drive"></img>
                            <div className="bg-gray-300 h-60 text-center">
                                <p className="py-5">Tuviste un accidente<br /> regresando a casa</p>
                                <div className="w-full flex flex-row justify-center mt-4">
                                    <div className="w-5/12">
                                        <p className="font-bold text-left">Daños:</p>
                                    </div>
                                    <div className="w-5/12">
                                        <p className="text-left">$150,000</p>
                                    </div>
                                </div>
                                <div className="w-full flex flex-row justify-center mt-4">
                                    <div className="w-5/12">
                                        <p className="font-bold text-left">Tu <br /> pagas:</p>
                                    </div>
                                    <div className="w-5/12">
                                        <p className="text-left">5% del valor <br /> de tu auto ($12,500)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="w-9/12">
                            <img className="w-full" src={"./Rectangle1.png"} alt="drive"></img>
                            <div className="bg-gray-300 h-60 text-center">
                                <p className="py-5">Tuviste un accidente<br /> regresando a casa</p>
                                <div className="w-full flex flex-row justify-center mt-4">
                                    <div className="w-5/12">
                                        <p className="font-bold text-left">Daños:</p>
                                    </div>
                                    <div className="w-5/12">
                                        <p className="text-left">$150,000</p>
                                    </div>
                                </div>
                                <div className="w-full flex flex-row justify-center mt-4">
                                    <div className="w-5/12">
                                        <p className="font-bold text-left">Tu <br /> pagas:</p>
                                    </div>
                                    <div className="w-5/12">
                                        <p className="text-left">5% del valor <br /> de tu auto ($12,500)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                            <div className="w-9/12">
                                <img className="w-full" src={"./Rectangle1.png"} alt="drive"></img>
                                <div className="bg-gray-300 h-60 text-center">
                                    <p className="py-5">Tuviste un accidente<br /> regresando a casa</p>
                                    <div className="w-full flex flex-row justify-center mt-4">
                                        <div className="w-5/12">
                                            <p className="font-bold text-left">Daños:</p>
                                        </div>
                                        <div className="w-5/12">
                                            <p className="text-left">$150,000</p>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row justify-center mt-4">
                                        <div className="w-5/12">
                                            <p className="font-bold text-left">Tu <br /> pagas:</p>
                                        </div>
                                        <div className="w-5/12">
                                            <p className="text-left">5% del valor <br /> de tu auto ($12,500)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center">
                            <div className="w-9/12">
                                <img className="w-full" src={"./Rectangle1.png"} alt="drive"></img>
                                <div className="bg-gray-300 h-60 text-center">
                                    <p className="py-5">Tuviste un accidente<br /> regresando a casa</p>
                                    <div className="w-full flex flex-row justify-center mt-4">
                                        <div className="w-5/12">
                                            <p className="font-bold text-left">Daños:</p>
                                        </div>
                                        <div className="w-5/12">
                                            <p className="text-left">$150,000</p>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row justify-center mt-4">
                                        <div className="w-5/12">
                                            <p className="font-bold text-left">Tu <br /> pagas:</p>
                                        </div>
                                        <div className="w-5/12">
                                            <p className="text-left">5% del valor <br /> de tu auto ($12,500)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Carousel>
            </div>
        </>
    )
}
