import { UserName } from '../components/UserName'
import { NewReclamation } from '../components/NewReclamation';

export const Siniestros = () => {
  /* const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noData, setNodata] = useState(false); */

  /* const cardAccident = useRef<HTMLHeadingElement>(null)

  const getData = (data: any) => {
    setData(data)
  } */

  /* useEffect(() => {
    getUserAccidents()
  }, []) */

  /* const getUserAccidents = async () => {
    setLoading(true)
    try {
      let response = await server(true).post('/pdf-bot/get-accident',
        {
          "clientId": userData().id
        });
      if (response.data && response.data.status === 'OK' && response.data.accidents.length > 0) {
        setLoading(false)
        return setData(response.data.accidents)
      }
      setLoading(false)
      setNodata(true)
    } catch (error) {
      setLoading(false)
      alert('Hubo un error al traer tus polizas.', 'error', '#c35353', 2000)
      setNodata(true)
    }
  } */

  return (
    <div className='flex flex-col justify-center py-10 bg-white'>
      <div className='lg:flex lg:flex-row w-8/12 self-center'>
        <UserName />
      </div>
      {/* <div className='lg:flex lg:flex-row w-8/12 self-center'>
        <Title title="MIS SINIESTROS" />
        {
          data.length > 0 ? <NewOldFilters getData={()=> getData} newData={data} reference={cardAccident} fieldStart={"fechaSiniestro"} fieldEnd={"fechaSiniestro"} /> : ''
        }

      </div>

      <div className='flex lg:justify-center w-full'>
        <div ref={cardAccident} className='lg:flex hidden flex-wrap lg:w-8/12 sm:w-full md:w-full place-items-center'>
          {
            loading ? <div className='flex text-white w-full justify-center'>
              <div className='blue1 mb-10 mt-10 p-5'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
            </div> : ''
          }
          {
            !noData ? '' : <div className='mb-10 mt-10 p-5 blue1 text-white'><h2 className='font-bold text-xl'>No cuentas con siniestros.</h2></div>
          }
          {
            data.map((e: any) => (
              <div className='lg:w-2/6 px-1 md:px-0 lg:px-0 py-5'>
                <CardAccident noSiniestro={e.accidentNumber} poliza={e.policyNumber} compania={e.companyId} ramo={e.policyTypeId} fechaSiniestro={e.date} pdf={e.pdf} />
              </div>
            ))
          }
        </div>

        <div className='lg:hidden flex w-full'>
          <div ref={cardAccident} className='flex justify-center flex-col w-full'>
            {
              data.map(({ noSiniestro, poliza, compania, ramo, fechaSiniestro, tipo, pdf }) => (
                <div className='flex w-full justify-center py-5'>
                  <CardAccident noSiniestro={noSiniestro} poliza={poliza} compania={compania} ramo={ramo} fechaSiniestro={fechaSiniestro} tipo={tipo} pdf={pdf} />
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className='flex lg:justify-center w-full'>
        <div className='flex flex-wrap lg:w-8/12 w-full lg:justify-start justify-center lg:pt-0 pt-5'>
          <p className='text-indigo-600 text-sm'>Para más información consúltelo con uno de nuestro agentes*</p>
        </div>
      </div>

      <div>
        <NewAccident />
      </div>
 */}
      <NewReclamation />

    </div>
  )
}
