import { server } from '../utils/connection';

export const getPolicies = async (id:string) => {
    try {
        let response = await server(true).post('/pdf-bot/get-policy',
            {
                "clientId": id
            });
        if (response.data && response.data.status !== 'ERROR' && response.data.policies.length > 0) {
            return response.data.policies
        }
        return []
    } catch (error) {
        console.log(error)
    }
}


