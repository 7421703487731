import { useState } from 'react'
import { alert } from '../utils/alert';

interface Props {
    title: any
    showControllers: boolean,
    createChart?: any
    deleteChart?: any
}

export const BarControllers = ({ title, showControllers, createChart, deleteChart }: Props) => {
    const [data, setData] = useState([
        {
            name: 'Gráfica 1',
            color: '#46ae2f',
        }
    ]);
    const [colors] = useState<any>([
        "#46ae2f",
        "#FFE688",
        "#364a1e",
        "#378f90",
        "#F88181",
        "#2553cf",
        "#000000"

    ])
    const [values, setValues] = useState<any>([])

    const addData = () => {
        let length = data.length
        if(length >= 6){
            return alert('Maximo de tablas creadas.', 'error', '#c35353', 2000)
        }
        console.log(colors[length + 1])

        let newObj = {
            name: `Gráfica ${length + 1}`,
            color: colors[length + 1],
        }
        createChart(newObj)
        setData(current => [...current, newObj]);
    }

    const deleteData = (index: number) => {
        let tempData = [...data];
        tempData.splice(index, 1);
        console.log(tempData)
        deleteChart(index)
        setData(tempData);
    }

    const saveData = (e: string, index: number, type: string) => {
        let newData = { "value": e, index: index, "type": type }
        let find = values.some((e: any) => e.index === newData.index && e.type === newData.type)
        if (find) {
            let tempValues = [...values];
            let filteredData = tempValues.filter(e => e.type !== newData.type || e.index !== newData.index)
            /* let updatedData = [...filteredData, { index: index, "value": e, "type": type }] */
            setValues(filteredData);
        }
        setValues((prevState: any) => {
            return [...prevState, { index: index, "value": e, "type": type }];
        });
    }

    return (
        <div>
            <div className='h-400'>
                <div className='flex justify-center blue1'>
                    <h2 className='font-bold text-white text-2xl p-3'>{title}</h2>
                </div>
                {
                    data.length > 0 ? data.map((element: any, i: number) => (
                        <div className={i % 2 === 1 ? 'gris3' : 'gris1'}>
                            <div className='flex flex-row justify-center p-3 items-center'>
                                <div style={{backgroundColor:element.color}} className={`h-4 w-4 mr-2 `}></div>
                                <p className='font-bold'>{element.name}</p>
                            </div>
                            {
                                showControllers ? <div className='flex flex-col items-center'>
                                    <div className='flex flex-col mt-2'>
                                        <label className='gris2'>Estado de pólizas</label>
                                        <select className='gris3 p-2' onChange={(e) => { saveData(e.target.value, i, "poliza") }}>
                                            <option value="">Elige una opción</option>
                                            <option value="activas">Activas</option>
                                        </select>
                                    </div>

                                    <div className='flex flex-col mt-2'>
                                        <label className='gris2'>Compañía</label>
                                        <select className='gris3 p-2' onChange={(e) => { saveData(e.target.value, i, "corp") }}>
                                            <option value="">Elige una opción</option>
                                            <option value="GNP">GNP</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col mt-2'>
                                        <label className='gris2'>Ramo</label>
                                        <select className='gris3 p-2' onChange={(e) => { saveData(e.target.value, i, "field") }}>
                                            <option value="">Elige una opción</option>
                                            <option value="Gastos Médicos">Gastos Médicos</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col mt-2'>
                                        <label className='gris2'>Fecha</label>
                                        <select className='gris3 p-2' onChange={(e) => { saveData(e.target.value, i, "year") }}>
                                            <option value="">Elige una opción</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                        </select>
                                    </div>
                                </div> : ''}
                            <div className='flex flex-col p-4'>
                                <button className='morado1' onClick={addData}>Agregar gráfica comparitva +</button>
                                <button className='morado1' onClick={() => deleteData(i)}>Eliminar gráfica comparitva -</button>
                            </div>
                        </div>
                    )) : ''
                }
                <div className='flex flex-row justify-end mt-3'>
                    <button onClick={() => {/* getData() */}} className='morado2 text-white font-bold rounded-md p-3'>GENERAR</button>
                </div>
            </div>
        </div>
    )
}
