import axios from 'axios';
import { headerAuth } from './constants';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8084/api/v1/';

const validateToken = async () => {

    try {
        const validate = await axios.get(API_URL + "users/validateToken", { headers: headerAuth })
        return validate
    } catch (error: any) {
        if (error.response.data.message === "Expired JWT token") {
            localStorage.removeItem('userData')
            localStorage.removeItem('type')
            localStorage.removeItem('userToken')
            localStorage.setItem("logged", "false")
            window.location.reload();
        }
    }

}

export default validateToken;