import { validateStatus } from '../utils/validateStatus';
import toBase64 from '../utils/toBase64';
import formatDate from '../utils/formatDate';
import userData from '../utils/getUserData';
import validateUserRole from '../utils/validateUserRole';
import { useEffect, useState } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';

export const Card = ({ ...props }: any) => {

  const [role, setRole] = useState(false)
  /* const [payment, setPayment] = useState(props.payment) */

  const downloadPdf = (pdf: string) => {
    let byte = toBase64(pdf);
    let blob = new Blob([byte], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob), "_blank");
  }

  const validateRole = async () => {
    let response = await validateUserRole(userData().roles, 'EDIT_COBRANZA')
    return setRole(response);
  }

  useEffect(() => {
    validateRole()
  }, [])

  return (

    <div className='w-96'>
      {
        [props][0].owner ? [props].map((e) => (
          <div className={` bg-gray-300 text-center`}>
            <p className='text-gray-700 font-bold '> {e.owner} </p>
          </div>
        )) : ''
      }

      <div className='flex flex-col border-none bg-white'>

        <div className='flex w-full py-2'>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td className='px-4 gris8'>Nombre</td>
                <td>{props.name ? props.name : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Concepto</td>
                <td>{props.concepto ? props.concepto : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Ramo</td>
                <td>{props.ramo ? props.ramo : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Compañia</td>
                <td>{props.compania.companyName ? props.compania.companyName : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Póliza</td>
                <td>{props.poliza ? props.poliza : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Vigencia</td>
                <td>{props.vigenciaInicio && props.vigenciaFin ? `${formatDate(props.vigenciaInicio)} al ${formatDate(props.vigenciaFin)}` : '-'}</td>
              </tr>
              <tr>
                <td className='px-4 gris8'>Prima</td>
                <td>{props.prima ? `$ ${props.prima} MX` : '-'} </td>
              </tr>

            </tbody>
          </table>
          <div className='relative'>
            {[props].length > 0 ? <div className='absolute top-0 right-[-1] ml-5'>
              <svg className='text-2xl blue1color mt-2 cursor-pointer' width="25" height="33" viewBox="0 0 25 33" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => downloadPdf(props.pdf)}>
                <path d="M17.2174 29.6076C17.5453 29.1873 17.842 28.7981 18.1543 28.4245C18.2168 28.3467 18.4042 28.3622 18.5291 28.3622C19.8407 28.3622 21.1524 28.3622 22.4484 28.3622C22.87 28.3622 22.9013 28.3155 22.9013 27.9108V6.53795C22.9013 6.13322 22.87 6.10209 22.4641 6.10209C21.2149 6.10209 19.9657 6.10209 18.7165 6.10209C18.2324 6.10209 18.0606 5.93085 18.0606 5.43272C18.0606 4.20297 18.0606 2.98878 18.0606 1.75902C18.0606 1.33872 18.045 1.32316 17.639 1.32316C13.2356 1.32316 8.83214 1.32316 4.41308 1.32316C4.02271 1.32316 3.99148 1.33872 3.99148 1.75902C3.99148 4.52986 3.99148 7.30071 3.99148 10.0716C3.99148 10.5697 3.94463 10.6164 3.46057 10.6164C2.61736 10.6164 2.64859 10.6786 2.64859 9.80692C2.64859 6.81815 2.64859 3.82937 2.64859 0.85616C2.64859 0.124532 2.77351 0 3.47618 0C8.55107 0 13.6416 0 18.7165 0C19.0756 0 19.3411 0.108966 19.5909 0.35803C21.0275 1.79015 22.4641 3.22227 23.9163 4.6544C24.1349 4.87233 24.2442 5.10583 24.2442 5.41716C24.2286 13.2938 24.2286 21.1705 24.2286 29.0472C24.2286 29.4986 24.0568 29.6698 23.6352 29.6698C21.6052 29.6698 19.5597 29.6698 17.5297 29.6543C17.436 29.6543 17.3736 29.6387 17.2174 29.6076Z" fill="#73A3D5" />
                <path d="M10.9563 24.1759C10.9563 23.5688 10.9563 22.9461 10.9563 22.339C10.9563 21.872 11.2061 21.6074 11.6746 21.6074C12.8613 21.5918 14.0481 21.5918 15.2348 21.6074C15.7189 21.6074 15.9375 21.872 15.9375 22.3701C15.9375 23.5376 15.9375 24.7051 15.9375 25.8882C15.9375 26.3552 15.9531 26.3707 16.4215 26.3707C16.9056 26.3707 17.4053 26.3552 17.8894 26.3707C18.0299 26.3707 18.2329 26.433 18.2797 26.5264C18.3266 26.6198 18.2641 26.8222 18.186 26.9311C16.7651 28.8458 15.3441 30.7605 13.9231 32.6752C13.5952 33.1111 13.3141 33.1111 12.9862 32.6596C11.5809 30.7605 10.1599 28.877 8.75456 26.9623C8.66087 26.8377 8.56718 26.6042 8.61402 26.542C8.6921 26.433 8.89509 26.3707 9.06686 26.3707C9.58216 26.3552 10.0818 26.3552 10.5971 26.3707C10.8626 26.3707 10.9875 26.2773 10.9719 25.9971C10.9407 25.39 10.9563 24.783 10.9563 24.1759Z" fill="#73A3D5" />
                <path d="M9.64398 29.6383C9.4566 29.6539 9.3473 29.6539 9.23799 29.6539C7.28611 29.6539 5.33423 29.6695 3.38235 29.6695C2.78897 29.6695 2.64844 29.5138 2.64844 28.9223C2.64844 26.634 2.64844 24.3613 2.64844 22.073C2.64844 21.606 2.67967 21.5749 3.16373 21.5749C4.05379 21.5749 4.02256 21.4815 4.00695 22.4155C4.00695 24.2523 4.00695 26.0892 4.00695 27.926C4.00695 28.3463 4.03818 28.3774 4.45978 28.3774C5.75583 28.3774 7.05188 28.3774 8.34793 28.3774C8.61339 28.3774 8.78515 28.4553 8.9413 28.6732C9.1443 28.969 9.37853 29.2647 9.64398 29.6383Z" fill="#73A3D5" />
                <path d="M5.25981 14.8175C4.99435 14.8019 4.71327 14.8175 4.41659 14.8175C4.33851 15.7982 4.30728 15.6425 5.10365 15.6114C5.1505 15.6114 5.19735 15.6114 5.25981 15.5958C5.49403 15.5647 5.6658 15.4557 5.6658 15.1911C5.6658 14.9264 5.47842 14.833 5.25981 14.8175Z" fill="#73A3D5" />
                <path d="M9.76172 14.8337V17.3088C10.3082 17.4177 10.7923 17.1531 10.9172 16.6083C10.9953 16.2814 11.0109 15.9234 10.9485 15.5809C10.8704 15.0049 10.4019 14.7247 9.76172 14.8337Z" fill="#73A3D5" />
                <path d="M18.4848 11.7676H1.62061C1.02724 11.7676 0.871094 11.9077 0.871094 12.4992C0.871094 14.8965 0.871094 17.2937 0.871094 19.6754C0.871094 20.2513 1.01163 20.407 1.58938 20.407H10.0527H18.4848C19.047 20.407 19.2187 20.2513 19.2187 19.691C19.2187 17.2937 19.2187 14.8965 19.2187 12.4992C19.2344 11.9232 19.0626 11.7676 18.4848 11.7676ZM6.19581 16.7489C5.74298 16.8734 5.25891 16.889 4.77485 16.9045C4.47816 16.9201 4.35324 16.9668 4.36886 17.2937C4.40009 17.6673 4.36886 18.0409 4.38447 18.4145C4.38447 18.6169 4.32201 18.6791 4.11902 18.6791C3.66618 18.6636 3.21335 18.6791 2.72928 18.6791V13.5422C2.77613 13.5422 2.77613 13.511 2.80736 13.511C3.82233 13.511 4.85292 13.4955 5.8679 13.5577C6.63303 13.6044 7.19517 14.196 7.27325 14.8965C7.38255 15.7993 7.00779 16.5154 6.19581 16.7489ZM12.2544 17.3871C11.8016 18.3055 10.974 18.6324 10.0215 18.6947C9.52181 18.7258 9.00652 18.6636 8.49122 18.648C8.27261 18.648 8.22577 18.539 8.22577 18.3367C8.22577 17.5895 8.22577 16.8423 8.22577 16.1106C8.22577 15.3635 8.22577 14.6163 8.22577 13.8846C8.22577 13.6667 8.28823 13.5577 8.50684 13.5577C9.22513 13.5577 9.95903 13.4799 10.6773 13.5733C11.7548 13.6978 12.4418 14.4917 12.5355 15.5658C12.598 16.204 12.5511 16.8111 12.2544 17.3871ZM17.3605 14.6941C17.3605 14.7408 17.2044 14.8342 17.1263 14.8342C16.5798 14.8498 16.0489 14.8342 15.5024 14.8498C15.3931 14.8498 15.2838 14.8653 15.1588 14.8653V15.5036H16.6891C17.1419 15.5036 17.1419 15.5036 17.1419 15.9394C17.1419 16.7177 17.1419 16.7177 16.3456 16.7177H15.1276C15.1276 17.2937 15.112 17.8385 15.1276 18.3678C15.1432 18.6324 15.0495 18.7103 14.7997 18.6947C14.4562 18.6791 14.097 18.6791 13.7535 18.6947C13.5817 18.6947 13.5193 18.6324 13.5193 18.4612C13.5193 16.889 13.5193 15.3012 13.5349 13.729C13.5349 13.6511 13.6442 13.511 13.7066 13.511C14.909 13.4955 16.1113 13.4955 17.3762 13.4955C17.3605 13.9158 17.3762 14.3049 17.3605 14.6941Z" fill="#73A3D5" />
              </svg>
              {role ? <BsFillPencilFill className='text-2xl blue1color mt-4 cursor-pointer' onClick={() => props.edit(props.poliza)} /> : ''}
              {/* {userData().roles[0] === 'ROLE_USER' && !props.payment ? <BsFillCreditCard2FrontFill className='text-2xl blue1color mt-4 cursor-pointer' onClick={() => props.create(props.poliza)} /> : ''} */}
            </div> : ''}
          </div>

        </div>

        {validateStatus(props.vigenciaFin, true)}

        {/* <div className='bg-blue-300 w-full text-center'>
          {
            [props].map((e) => (
              <h1 className='text-white'>autopago ligado a tarjeta *{e.tarjeta} </h1>
            ))
          }
        </div> */}

      </div>
    </div>
  )
}
