import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export const alert = (title:string, icon:any, color:string, time?:number) => {
    const MySwal = withReactContent(Swal)

    const Toast = MySwal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: time ? time : 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,
        title: title,
        background: color,
        color: '#ffff',
        padding: '4em',
    })
}

 