import { ReactNode } from 'react';
import { AiOutlineClose } from "react-icons/ai";

interface Props {
    isVisible: boolean,
    onClose: () => void,
    children?: ReactNode,
    size?:string
}

export const Modal = ({ isVisible, onClose, children, size } : Props) => {

    const handleClose = (e) => {
        if (e.target.id === 'wrapper') onClose();
    }

    if (!isVisible) return null;
    return (
        <div className='fixed inset-0 bg-black bg-opacity-25 z-50 backdrop-blur-sm flex justify-center items-center px-28' id="wrapper" onClick={handleClose}>
            <div className={`flex flex-col lg:w-${size ? size : '[1000px]' }  w-full`}>
                <div className='bg-white rounded flex flex-col'>
                    <div className='relative w-full z-50'>
                        <div className='absolute top-3 right-4'>
                            <button className='text-white text-2xl' onClick={() => onClose()}><AiOutlineClose /></button>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

