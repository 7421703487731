import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { sortCorp } from '../hooks/useCorp'
import { sortType } from '../hooks/useType'
import { FaRedo } from 'react-icons/fa'
import { sortData } from '../hooks/useSort'
import { getPolicies } from '../utils/getPolicies'
import userData from '../utils/getUserData'
import { server } from '../utils/connection'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

interface Props {
    reference: React.RefObject<HTMLHeadingElement>,
    newData: any,
    fieldStart: string,
    fieldEnd?: string,
    justify?: string,
    getData: (data: any) => void,
    byCorp?: string;
    byType?: string;
    length?: string;
    newVersion?: boolean;
    sendData?: boolean,
    save?: any
}
const tl = gsap.timeline();

export const NewOldFilters = ({ reference, newData, justify, save = [], getData, length, newVersion, sendData = false }: Props) => {
    const [saved, setSaved] = useState([])
    const [data, setData] = useState([])
    const [statusSelect, setStatusSelect] = useState("")
    const [completedSelect, setCompletedSelect] = useState("")
    const [finishedSelect, setFinishedSelect] = useState("")
    /* const [searchValue, setSearchValue] = useState(""); */
    const [searchData, setSearchData] = useState([])
    const [companies, setCompanies] = useState([])
    const [policies, setPolicies] = useState([])
    const [disabledSelect, setDisabledSelect] = useState(true)

    const time = useRef<HTMLSelectElement>(null);
    const corp = useRef<HTMLSelectElement>(null);
    const type = useRef<HTMLSelectElement>(null);

    const getCompanies = async () => {
        try {
            let companies = await server(true).get('/pdf-bot/companies-policies');
            setCompanies(companies.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getPolice = (policie: any) => {
        filter()
        let dataPolicie = JSON.parse(policie)
        setPolicies(dataPolicie.policiesType)
        setDisabledSelect(false)
    }

    const filter = async () => {

        tl.from(reference.current, { duration: 1, alpha: 0 });

        let indexTime: any = time.current?.options.selectedIndex
        let indexCorp: any = corp.current?.options.selectedIndex;
        let indexType: any = type.current?.options.selectedIndex;
        let currentData = sendData ? save : saved;

        if (indexTime && indexTime !== 0) {
            let getTime: any = time.current?.options[indexTime].value;
            const map = new Map<string, any>([['VIGENTE', sortData(currentData, getTime)], ['VENCIDA', sortData(currentData, getTime)], ['PRÓXIMA A VENCER', sortData(currentData, getTime)]]);
            setStatusSelect(getTime)
            currentData = map.get(getTime)
            console.log(currentData)
        }

        if (indexCorp && indexCorp !== 0) {
            let currentCorp: any = corp.current?.options[indexCorp].value;
            setCompletedSelect(currentCorp)
            currentData = sortCorp(currentData, currentCorp)
        }

        if (indexType && indexType !== 0) {
            let currentType: any = type.current?.options[indexType].value;
            console.log(JSON.parse(currentType))
            setFinishedSelect(currentType)
            currentData = sortType(currentData, currentType)
        }

        setData(currentData)
    }

    const reset = () => {
        setStatusSelect("")
        setCompletedSelect("")
        setFinishedSelect("")
        /* setSearchValue("") */
        setData(sendData ? save : saved)
    }

    const searchItem = (item) => {
        let searchData = sendData ? save : saved;
        setData(searchData.filter((e) => e.policyNumber === item.name))
    }

    const saveSaved = async () => {
        let policies = await getPolicies(userData().id)
        setSaved(sendData ? newData : policies)
    }

    const formatSearchData = () => {
        let newSearchData = []
        let searchData = sendData ? save : saved;
        if (!searchData) {
            return;
        }
        searchData.map((e) => {
            let validate = newSearchData.find(c => c.name && c.name === e.policyNumber);
            if (!validate) {
                let newElement = { 'name': e.policyNumber }
                newSearchData.push(newElement)
            }

            return newSearchData;
        })
        setSearchData(newSearchData)
    }
    
    const formatResult = (item: any) => {
        return (
            <>
                <span style={{ textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    //* eslint-disable */
    useEffect(() => {
        // eslint-disable-next-line

        saveSaved()
        // eslint-disable-next-line

        getCompanies()
    }, [])

    

    useEffect(() => {
        getData(data)
    }, [data])

    useEffect(() => {
        // eslint-disable-next-line
        formatSearchData()
    }, [newData])
    /* eslint-enable */
    if (newVersion) {
        return (
            <div className={`${newVersion ? 'flex' : ''} flex justify-between flex-row`}>
                <div className={`${justify ? justify : "justify-end"} ${length ? length : ""} flex-col px-2 flex lg:mt-0 mt-5`} >
                    <p className={`text-gray-400 text-sm py-2`}> {newVersion ? 'FILTRAR POR:' : "VIGENCIA:"} </p>
                    <select ref={time} onChange={() => { filter() }} value={statusSelect} className={`gris3 h-10 pl-3 border-transparent`}>
                        <option value="">Elige una vigencia</option>
                        <option value="VIGENTE">Vigentes</option>
                        <option value="PRÓXIMA A VENCER">Próximas a vencer</option>
                        <option value="VENCIDA">Vencidas</option>
                    </select>
                </div>
                <div className={`${justify ? justify : "justify-end"} ${length ? length : ""} flex-col px-2 flex lg:mt-0 mt-5`} >
                    <p className={`text-gray-400 text-sm py-2 `}>COMPAÑÍA:</p>
                    <select ref={corp} onChange={(e) => { getPolice(e.target.value) }} value={completedSelect} className={`gris3 h-10 pl-3 border-transparent`}>
                        <option value="">Elige una compañia</option>
                        {
                            companies.map((e: any, k) => <option key={k} value={JSON.stringify(e)}>{e["company"].companyShowName}</option>)
                        }
                    </select>
                </div>
                <div className={`${justify ? justify : "justify-end"} ${length ? length : ""} flex-col px-2 flex lg:mt-0 mt-5`} >
                    <p className={`text-gray-400 text-sm py-2`}>RAMO:</p>
                    <select ref={type} onChange={() => { filter() }} value={finishedSelect} className={`gris3 h-10 pl-3 border-transparent`} disabled={disabledSelect}>
                        <option value="">Elige un ramo...</option>
                        {
                            policies.length > 0 ? policies.map((e: any, i) => <option key={i} value={JSON.stringify(e)}>{e.policyTypeName}</option>) : ''
                        }
                    </select>
                </div>
                <div className={`flex flex-row ${newVersion ? '' : 'hidden'} items-end justify-center px-4 w-96 space-x-5`} >
                    <button className={`text-xl h-12 flex items-center blue1 p-4 text-white rounded-md`} onClick={() => { reset() }} >  <FaRedo className='text-white'></FaRedo></button>
                    <div className='w-full'>
                        <p className={`text-gray-400 text-sm`}>BUSCAR PÓLIZA:</p>
                        <ReactSearchAutocomplete
                            items={searchData}
                            formatResult={formatResult}
                            onSelect={searchItem}
                            styling={{ borderRadius: "0px", backgroundColor: '#eaecec' }}
                        />
                    </div>
                </div>
            </div>

        )
    } else {
        return (
            <div className={`flex flex-col px-2 lg:flex-row  ${justify ? justify : "justify-end"} ${length ? length : "lg:w-4/6 w-full"}  lg:mt-0 mt-5`} >
                <p className={`text-gray-400 text-sm py-2 ${newVersion ? 'px-4' : 'px-5'}`}> FILTRAR POR: </p>
                <div className='flex flex-row'>
                    <select ref={time} onChange={(e) => { e.target.value === "TODAS" ? reset() : filter() }} value={statusSelect} className={`gris1 border-gray-100 w-48 ${newVersion ? 'w-60 h-8 pl-3' : 'px-2'} `}>
                        <option value="">Elige una opción</option>
                        <option value="TODAS">Todas</option>
                        <option value="VIGENTE">Vigentes</option>
                        <option value="PRÓXIMA A VENCER">Próximas a vencer</option>
                        <option value="VENCIDA">Vencidas</option>
                    </select>
                </div>
            </div>
        )
    }

}
