import { useEffect, useState } from "react";
import { server } from "../utils/connection";
import convertBase64 from "../utils/convertBase64";
import toBase64 from "../utils/toBase64";
import { alert } from '../utils/alert';
import userData from "../utils/getUserData";

export const LoadArticle = ({ article, current, refresh }: any) => {
  const [file, setFile] = useState<any>([])
  const [fileName, setFileName] = useState("")
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)

  const downloadPdf = (pdf: string) => {
    let byte = toBase64(pdf);
    let blob = new Blob([byte], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob), "_blank");
  }

  const uploadArticle = async () => {
    setLoading(true)
    let stringFile = await convertBase64(file[0]) as string
    if ([stringFile].length === 0) {
      return setLoading(false);
    }

    try {
      await server(true).post(`/users/${current.id}/article`, {
        fileName,
        fileData: stringFile.split(',')[1]
      });
      refresh()
      setLoading(false)
      alert('El árticulo se a guardado correctamente.', 'success', '#73A3D5', 1500)
    } catch (error) {
      alert('Hubo un error al subir el árticulo.', 'error', '#c35353', 2000)
      setLoading(false)
      return error;
    }
  }

  const updateArticle = async () => {
    setLoading(true)
    let stringFile = await convertBase64(file[0]) as string
    if ([stringFile].length === 0) {
      return;
    }
    try {
      await server(true).post(`/users/${current.id}/article/${article.id}`, {
        fileName,
        fileData: stringFile.split(',')[1]
      });
      setUpdate(false)
      setLoading(false)
      refresh()
      alert('El árticulo se a guardado correctamente.', 'success', '#73A3D5', 1500)
    } catch (error) {
      alert('Hubo un error al subir el árticulo.', 'error', '#c35353', 2000)
      setUpdate(false)
      setLoading(false)
      console.log(error)
    }
  }
    //* eslint-disable */
  useEffect(() => {
    if (!update && file.length > 0) {
      // eslint-disable-next-line
      uploadArticle()
    }
  }, [file])

  useEffect(() => {
    if (update) {
      // eslint-disable-next-line
      updateArticle()
    }
  }, [update])
    /* eslint-enable */
  if (article) {
    return (
      <div className='flex flex-col items-center text-center'>
        <h1 className='text-xl gris8'>Documentos Árticulo 492</h1>
        <p className='p-1 w-full px-2 morado2color text-xs mt-2 mb-2'>{article ? article.fileName : "Usuario sin articulo"}</p>
        <div className="flex flex-row w-full">
          <div className="w-6/12">
            <button type='submit' className='gris8bg text-white px-2 h-12 rounded-md text-base font-bold mt-3 mb-3' onClick={() => { downloadPdf(article.fileData) }}>DESCARGAR</button>
          </div>
          <div className="w-6/12 flex justify-center items-center">
            {
              userData().roles[0] === "ROLE_SUPER_ADMIN" || userData().roles[0] === "ROLE_ADMIN" ?
                <label htmlFor="file-upload" className="gris8bg relative text-white py-3 px-2 rounded-md text-base font-bold mt-3 mb-3">
                  {
                    loading ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-28 h-6 animate-spin text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg> : <>
                      <span className="text-white font-bold text-base">ACTUALIZAR</span>
                      <input id="file-upload" name="file-upload" type="file" className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer" onChange={(event) => {
                        setFile(event.currentTarget.files);
                        setUpdate(true)
                        setFileName(event.currentTarget.files[0] ? event.currentTarget.files[0]['name'] : fileName ) 
                      }}
                        disabled={loading ? true : false}
                      />
                    </>
                  }
                </label> : <></>
            }
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='flex flex-col items-center text-center'>
      <h1 className='text-xl gris8'>Usuario no cuenta con árticulo</h1>
      <p className='p-1 w-full px-2 morado2color text-xs mt-2 mb-2'>{article ? article.fileName : "-"}</p>
      {userData().roles[0] === "ROLE_SUPER_ADMIN" || userData().roles[0] === "ROLE_ADMIN" ?
        <label htmlFor="file-upload" className="relative gris8bg text-white py-4 px-4 rounded-md font-bold mt-3 mb-3">
          {
            loading ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg> :
              <>
                <span className="text-white font-bold text-base">CARGAR ÁRTICULO</span>
                <input id="file-upload" name="file-upload" type="file" className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer" onChange={(event) => {
                  setFile(event.currentTarget.files);
                  setFileName(event.currentTarget.files[0] ? event.currentTarget.files[0]['name'] : fileName ) 
                }}
                  disabled={loading ? true : false}
                />
              </>
          }
        </label> :
        <></>
      }
    </div>
  )
}
