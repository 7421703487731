import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import MyTextInput from './MyTextInput';
import { server } from '../utils/connection';
import { alert } from '../utils/alert';
import MyDateInput from './MyDateInput';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import userData from '../utils/getUserData';
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from './Modal';
import { SubmitButton } from './SubmitButton';

export const PayPolicie = ({ policie, reload, newCard }) => {
    /* const [corps, setCorps] = useState<any>([]) */
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    /* const [sub, setSub] = useState(false)
    const [type, setType] = useState([]) */
    const [cardError] = useState(false)
    const [deleteCard, setDeleteCard] = useState(false)
    const [cardToDelete, setCardToDelete] = useState('')
    /* const [fileError, setFileError] = useState(false) */
    const [cards, setCards] = useState([])
    const [card, setCard] = useState("")

    /* const getCorps = async () => {
        try {
            let response = await server(true).get(`/pdf-bot/companies-policies`);
            setCorps(response.data)
            let filteredCorp = response.data.filter((corpElement) => corpElement.company.id === policie[0].company.id);
            setType(filteredCorp[0].policiesType)
        } catch (error) {
            alert('Hubo un error al traer las compañias.', 'error', '#c35353', 2000)
        }
    } */

    const getUserCards = async () => {
        try {
            let response = await server(true).get(`/payment/stripe/get-cards/${userData().id}`);
            if (response.data.statu === 'ERROR') {
                return;
            }
            let addedProp = []
            let data = response.data.stripeResponse
            if(data.length === 0){
                return;
            }
            data.forEach(element => {
                element['checked'] = false
                addedProp.push(element)
            });
            setCards(addedProp)
        } catch (error) {
            console.log(error)
        }
    }

    const updatedChecked = (item) => {
        setCards(current =>
            current.map(obj => {
                if (item.checked && item.id === obj.id) {
                    return { ...obj, checked: false };
                }
                if (item.id === obj.id) {
                    return { ...obj, checked: true };
                }
                return obj;
            }),
        );
    }

    /* const validateCharge = () => {
        if (policie[0].chargeType === 'SUBSCRIPTION') {
            setSub(true)
        }
    } */

    const deleteCardRequest = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            await fetch('http://localhost:8084/api/v1/payment/stripe/delete-card', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "userId": userData().id,
                    "cardId": cardToDelete
                })
            });
            setCardToDelete('')
            getUserCards()
            setLoading(false)
            setDeleteCard(false)
        } catch (error) {
            alert('Hubo un error al borarr la tarjeta.', 'error', '#c35353', 2000)
        }
    }

    useEffect(() => {
       /*  getCorps() */
        /* validateCharge() */
        getUserCards()
    }, [])

    return (
        <div className='flex flex-col'>
            <div className='w-full blue1 p-3 px-8'>
                <h1 className='text-white font-bold'>PAGAR PÓLIZA</h1>
            </div>
            <div className='p-8'>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        "clientId": userData().id,
                        "companyId": policie[0].company.companyName,
                        "policyNumber": policie[0].policyNumber,
                        "dateFrom": policie[0].dateFrom,
                        "dateUntil": policie[0].dateUntil,
                        "concept": policie[0].concept,
                        "totalPremium": `$ ${policie[0].totalPremium}.00`,
                        "premiumType": policie[0].premiumType,
                        "type": policie[0].type,
                        "fileName": policie[0].fileName,
                        "fileData": policie[0].fileData
                    }}
                    onSubmit={async (values) => {
                        setStatus(true)
                        if (card.length === 0) {
                            setStatus(false)
                            return alert('Selecciona o agrega un metodo de pago.', 'error', '#c35353', 2000)
                        }
                        try {
                            await server(true).post(`/payment/stripe/create-charge`, {
                                "description": policie[0].chargeType === "CHARGE" ? "One time Payment" : 'SUBSCRIPTION',
                                "amount": policie[0].totalPremium,
                                "currency": "MXN",
                                "userId": values.clientId,
                                "policyId": policie[0].id,
                                "cardToken": card
                            });
                            alert('Poliza pagada correctamente.', 'success', '#73A3D5', 1500)
                            reload()
                            setStatus(false)
                        } catch (error) {
                            alert('Hubo un error al pagar la poliza.', 'error', '#c35353', 2000)
                            setStatus(false)
                        } 
                    }}
                >
                    <Form>
                        <div className='mb-4'>
                            <h1 className='font-bold text-sm'>REFERENTE A PÓLIZA:</h1>
                        </div>

                        <div className='flex lg:flex-row flex-col w-full'>
                            <div className='lg:w-6/12 w-full space-y-4'>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12 gris2'>Concepto</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" name="concept" label={''} disabled />
                                    </div>
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12 gris2'>Ramo</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" label={''} name={'type'} disabled />
                                    </div>
                                </div>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-3/12 gris2' >Compañia</h1>
                                    <div className='w-7/12'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" label={''} name={'companyId'} disabled />
                                    </div>
                                </div>

                            </div>
                            <div className='lg:w-6/12 w-full space-y-4'>
                                <div className='flex space-x-4'>
                                    <h1 className='py-2 w-2/12 gris2'>Póliza</h1>
                                    <div className='w-6/12'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" name="policyNumber" placeholder='No. póliza' label={''} disabled />
                                    </div>
                                </div>
                                <div className='flex space-x-4 just items-center'>
                                    <h1 className='py-2 w-2/12 gris2'>Vigencia</h1>
                                    <div className='flex space-x-2 items-center'>
                                        <div className='w-6/12'>
                                            <MyDateInput label='' class=" h-8 px-4 border-none w-full" name="dateFrom" disabled />
                                        </div>
                                        <p>al</p>
                                        <div className='w-6/12'>
                                            <MyDateInput label='' class=" h-8 px-4 border-none" name="dateUntil" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex space-x-4 items-center'>
                                    <h1 className='py-2 w-2/12 gris2'>Prima</h1>
                                    <div className='w-[150px]'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" placeholder='$' name="totalPremium" label={''} disabled />
                                    </div>
                                    <div className='w-[150px]'>
                                        <MyTextInput type="text" className=" px-4 py-2 border-none w-full" placeholder='Trimestral' name="premiumType" label={''} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col space-x-4 mt-4' style={{ overflow: 'scroll', height: '300px' }}>
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-separate border-spacing-y-4">
                                <thead className="text-xs text-gray-700 uppercase ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 gris2 text-base">
                                            Mis Tarjetas
                                        </th>
                                        <th scope="col" className="px-6 py-3 gris2 text-base">
                                            Expira
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        cards.length > 0 ? cards.map((e, i) => (
                                            <tr key={i} className={`${e.checked ? 'blue1 text-white' : 'gris1 text-black'} text-base cursor-pointer`}>
                                                <td className='px-5'>
                                                    <div className='flex items-center space-x-4' onClick={() => {
                                                        let validate = cards.some((e) => e.checked)
                                                        let filtered = cards.filter((e) => e.checked)
                                                        if (validate && !e.checked) {
                                                            updatedChecked(filtered[0])
                                                            updatedChecked(e)
                                                            setCard(e.id)
                                                        }
                                                        if (validate && filtered[0].id !== e.id) {
                                                            return;
                                                        }
                                                        updatedChecked(e)
                                                        setCard(e.id)
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            value={e.id}
                                                            className={`${e.checked ? 'circle-check' : 'circle'}`}
                                                            name="cardNumbers"
                                                            disabled />
                                                        <img className="h-10 w-10" src={`./${e.brand}.png`} alt="" />
                                                        <p>{e.brand}, {e.funding === 'credit' ? 'Credito' : 'Débito'} con terminación &#x2022;&#x2022;&#x2022;&#x2022;{e.last4}</p>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    {`${e.expMonth < 10 ? `0${e.expMonth}` : e.expMonth}/${e.expYear}`}
                                                </td>
                                                <td className="py-4">
                                                    <div onClick={() => {
                                                        setDeleteCard(true)
                                                        setCardToDelete(e.id)
                                                    }}><AiOutlineClose /></div>
                                                </td>
                                            </tr>
                                        )) : <div className='flex justify-end w-11/12 cursor-pointer' onClick={() => newCard()}><div className='flex items-center rounded-3xl gris1 p-2 px-3'> <BsFillPlusCircleFill className='gris2 mr-4' /> <span className='mr-4'>Agregar tarjeta de credito o debito</span> <img alt='test' src="/Visa.png" className='w-6' /><img alt="test" src="/Mastercard.png" className='w-6' /><img alt="test" src="/American_express.png" className='w-6' /></div></div>
                                    }
                                </tbody>
                            </table>
                            {
                                cards.length === 0 ? '' : <div className='flex justify-end w-11/12 cursor-pointer' onClick={() => newCard()}><div className='flex items-center rounded-3xl gris1 p-2 px-3'> <BsFillPlusCircleFill className='gris2 mr-4' /> <span className='mr-4'>Agregar tarjeta de credito o debito</span> <img alt='test' src="/Visa.png" className='w-6' /><img alt="test" src="/Mastercard.png" className='w-6' /><img alt="test" src="/American_express.png" className='w-6' /></div></div>
                            }
                            {cardError ? <div className='flex flex-col w-full justify-center items-center'><span className="text-red-600 font-bold "> Tarjeta requerida </span></div> : ''}
                        </div>
                        <div className='flex justify-center w-full mt-10'>
                            <button type="submit" className="naranja1 text-white py-2 px-8 rounded-md font-bold" disabled={status}> {
                                status ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8 animate-spin color-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg> : "PAGAR PÓLIZA"}
                            </button>
                        </div>
                    </Form>
                </Formik >
                <Modal isVisible={deleteCard} onClose={() => { setDeleteCard(false) }} size='auto'>
                    <div>
                        <div className="modal-header flex flex-shrink-0 items-center justify-between blueModal">
                            <div className='w-full blue1 p-3 px-8'>
                                <h1 className='text-white font-bold'>Borrar tarjeta</h1>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={deleteCardRequest}>
                                <div className='w-full flex flex-col justify-center items-center p-16'>
                                    <h1 className='font-bold mb-6 text-xl'>¿Borrar tarjeta?</h1>
                                    <div className='flex justify-center items-center space-x-4'>
                                        <button className='morado2 text-white p-3 w-40 rounded-md font-bold' onClick={() => { setDeleteCard(false) }} >Cancelar</button>
                                        <SubmitButton status={loading} text={'Borrar'} class='naranja1 text-white flex items-center justify-center px-6 rounded-md w-40 py-2 font-bold' />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div >
        </div >
    )
}
