import { useField } from 'formik'

interface Props {
    label:string;
    name: string;
    placeholder?: string;
    rows: number;
    cols: number;
    [x:string]: any;
    class?: string;
    classic?: boolean;
}

const classes = {
    default: "block w-full border-slate-100 py-3 px-4 text-gray-900 gris3 focus:border-indigo-500 focus:ring-indigo-500"
}

export default function MyTextArea({label, ...props}:Props) {
    
    const [field,meta] = useField(props)

    return (
        <>
            <label className={`${props.classic ? 'gris8' : ''}`}  htmlFor={props.id || props.name}> {label} </label>
            <textarea className={props.class ? props.class : classes.default} {...field} {...props} />
            {
                meta.touched && meta.error && (
                    <span className="text-red-600 font-bold"> {meta.error} </span>
                )
            }
        </>
    )
}
