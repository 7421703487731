import { FaRegEnvelope, FaPhoneVolume, FaMapMarkerAlt } from "react-icons/fa";
import { CarouselHome } from '../components/Carousel';
import { cards, dataCard } from "../utils/constants";
import { Element } from 'react-scroll'
import { BsChevronCompactRight } from "react-icons/bs";
import { Link } from 'react-scroll'

export const Home = () => {
  return (
    <div>
      <Element name="test" className="element"></Element>
      <div className="relative">
        <div className="absolute z-10 inset-x-0 " />
        <div className="relative sm:overflow-hidden">

          <div className="absolute' inset-0'">

            <img
              className="lg:h-full h-80 w-full object-cover"
              src="./home.png"
              alt="People working on laptops"
            />
            <Element name="test1" className="element"></Element>

          </div>
        </div>

      </div>

      <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8 ">
        <h1 className="text-center font-bold text-4xl">
          Nosotros nos encargamos de <br /> cuidarte a ti y a los tuyos
        </h1>
        <div className="mt-6 grid grid-cols-1 py-10 gap-12 md:grid-cols-6 lg:grid-cols-5">
          {
            cards.map(({ title, description, info, Icon }) => (
              <div key={info} className="col-span-1 flex justify-center text-center md:col-span-2 lg:col-span-1">
                <div className="grid place-items-center text-center">
                  <Icon className="text-6xl blue2" />
                  <h1 className="text-2xl font-bold blue2 py-3"> {title} </h1>
                  <p className="font-medium py-3"> {description} </p>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div className="lg:block hidden">
        <div className="flex flex-col bg-white">
          <div className="flex w-full justify-center py-10 sm:px-6 lg:px-8">
            <h1 className="text-center font-bold text-4xl">Estos son ejemplos de las situaciones <br /> en las que podemos ayudarte</h1>
          </div>
          <div className="flex lg:flex-row flex-col justify-center space-x-12 h-full w-full">
            {
              dataCard.map((cardElement) => (
                <div className="border gris3 ">
                  <div>
                    <img alt="url" src={cardElement.image} className="w-80 h-42" />
                  </div>
                  <div className="w-full flex flex-col items-center justify-center">
                    <div>
                      <p>{cardElement.title}</p>
                    </div>
                    <div className="w-full flex flex-col mb-4">
                      <div className="w-full flex flex-row justify-center">
                        <div className="w-6/12">
                          <p className="font-bold text-left text-lg"> {cardElement.dato1} </p>
                        </div>
                        <div className="w-4/12">
                          <p className="text-right">{cardElement.value1}</p>
                        </div>
                      </div>
                      <div className="w-full flex flex-row justify-center mt-4">
                        <div className="w-5/12">
                          <p className="font-bold text-left text-lg">{cardElement.dato2}</p>
                        </div>
                        <div className="w-5/12">
                          <p className="text-right">{cardElement.value2}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            {/* {
              dataCard.map((cardElement ) => (
                <div key={cardElement.image} className="flex flex-col w-2/12 items-center justify-center">
                  <div className="w-full">
                    <img alt="url" src={cardElement.image} className="w-full h-auto" />
                    <div className="relative">
                      <div className="absolute bottom-0 right-0">
                        <div className="blue2bg p-2 rounded-full mr-2 mb-3">
                          <cardElement.Icon className="text-2xl text-white" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gris3 w-full flex flex-col items-center justify-center">
                    <div>
                      <p>{cardElement.title}</p>
                    </div>
                    <div className="w-full flex flex-col  mb-4">
                      <div className="w-full flex flex-row justify-center">
                        <div className="w-5/12">
                          <p className="font-bold text-left text-lg"> {cardElement.dato1} </p>
                        </div>
                        <div className="w-5/12">
                          <p className="text-right">{cardElement.value1}</p>
                        </div>
                      </div>
                      <div className="w-full flex flex-row justify-center mt-4">
                        <div className="w-5/12">
                          <p className="font-bold text-left text-lg">{cardElement.dato2}</p>
                        </div>
                        <div className="w-5/12">
                          <p className="text-right">{cardElement.value2}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            } */}
          </div>

          <div className="w-full flex justify-center mt-10 mb-10">
            <Link activeClass="active" to={'test3'} spy={true} smooth={true} offset={-150} duration={500}>
              <button className="naranja1 text-white py-2 px-8 rounded-md font-bold">SOLICITAR COTIZACIÓN</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="block lg:hidden">
        <div className="flex flex-col bg-white">
          <div className="flex w-full justify-center py-10 sm:px-6 lg:px-8">
            <h1 className="text-center font-bold text-4xl">Estos son ejemplos de las situaciones <br /> en las que podemos ayudarte</h1>
          </div>
          <CarouselHome />
          <Link activeClass="active" to={'test3'} spy={true} smooth={true} offset={-150} duration={500}>
            <div className="self-center">
              <a href="/contacto"><button className="naranja1 text-white py-2 px-8 rounded-md mt-10 mb-10">SOLICITAR COTIZACIÓN</button></a>
            </div>
          </Link>
        </div>
      </div>


      <Element name="test2" className="element"></Element>
      <div className="grid sm:grid-cols-1 lg:grid-cols-3 blue3bg ">
        <div className="col-span-2 px-10 place-self-center h-3/6 lg:py-8 lg:gap-12 lg:h-5/6">
          <h1 className="text-white text-4xl font-bold mb-5">¿Por qué elegir a AC seguros? </h1>
          <p className="text-white">Comparar las ventajas y desventajas entre aseguradoras es tarea <br /> difícil, en AC Seguros te ayudamos a
            elegir la mejores opción<br /> acorde tu necesidades.
            Con más de 40 años de experiencia en el<br /> mercado asegurador brindaremos nuestra
            mejor atención para la<br /> seguridad y tranquilidad del cuidado de su patrimonio.</p>
          <div className="flex items-center mt-5 naranja2 cursor-pointer">
            <Link activeClass="active" to={'test3'} spy={true} smooth={true} offset={-150} duration={500}>
              <p className="text-sm">Contáctanos </p>
            </Link>
            <BsChevronCompactRight />
          </div>
        </div>
        <div className="place-self-end lg:visible invisible ">
          <img src="./Buho.png" alt="buho.png"></img>
        </div>
      </div>

      <Element name="test3" className="element"></Element>
      <div className="flex lg:flex-row flex-col w-full justify-center">
        <div className="flex flex-col lg:w-9/12 w-full justify-center ">

          <div className="w-full lg:block flex flex-col items-center justify-center">
            <div className="h-2 naranja1 mt-8 w-56" />
            <h1 className="blue1color text-4xl font-bold mt-4">Contáctanos</h1>
            <p>Envianos un correo o llámanos<br /> para atander cualquiera de tus dudas.  </p>
          </div>

          <div className="flex lg:flex-row flex-col justify-center w-full mb-10 mt-6">
            <div className="flex flex-col lg:w-3/12 w-full">
              <div className='flex flex-row lg:self-start self-center'>
                <FaRegEnvelope className='text-3xl mr-2 blue2' />
                <div className='flex flex-col'>
                  <p className='font-bold text-2xl'>Correo</p>
                  <p className='mt-1'>informacion@acseguros.mx</p>
                </div>
              </div>
              <div className='flex flex-row lg:self-start self-center mt-5 lg:mb-0 mb-5'>
                <FaPhoneVolume className='text-3xl mr-2 blue2' />
                <div className='flex flex-col'>
                  <p className='font-bold text-2xl'>Teléfonos</p>
                  <p className='mt-1'>(33)• 3630 • 0319</p>
                  <p className='mt-1'>(33)• 3630 • 0319</p>
                  <p className='mt-1'>(33)• 3630 • 0319</p>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full px-6 justify-center">
              <div className='flex flex-row w-full lg:justify-start justify-center mb-2 lg:mt-0 mt-4'>
                <FaMapMarkerAlt className='text-3xl lg:mr-2 blue2' />
                <p className='font-bold text-2xl'>Ubicación</p>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3732.9837894826182!2d-103.38740539550781!3d20.670238494873047!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae73d8f10a61%3A0x1392a060fa0a5fc4!2sJoaqu%C3%ADn%20Arrieta%20134%2C%20Vallarta%2C%2044690%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1686039329033!5m2!1ses!2smx"
                className="lg:w-[650px] w-full h-[300px] " style={{ border: 0 }} loading="lazy" title="ubicación"></iframe>
              {/* <Contact /> */}
            </div>
            <div className='flex flex-col justify-center lg:items-start items-center lg:w-3/12 w-full'>
              {/* <div className='flex flex-row w-full lg:justify-start justify-center mb-2 lg:mt-0 mt-4'>
                <FaMapMarkerAlt className='text-3xl lg:mr-2 blue2' />
                <p className='font-bold text-2xl'>Ubicación</p>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3732.9837894826182!2d-103.38740539550781!3d20.670238494873047!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae73d8f10a61%3A0x1392a060fa0a5fc4!2sJoaqu%C3%ADn%20Arrieta%20134%2C%20Vallarta%2C%2044690%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1686039329033!5m2!1ses!2smx"
                width="300" height="300" style={{ border: 0 }} loading="lazy" title="ubicación"></iframe> */}
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8 ">
          <h1 className="text-center font-bold text-4xl">
            Las mejores aseguradoras están con nosotros
          </h1>
          <p className="text-center text-lg py-10">
            Trabajamos con las mejores aseguradoras para asegurarte el <br /> mejor servicio y los mejores precios del mercado
          </p>
          <div className="flex flex-col w-full">
            <div className="flex lg:flex-row flex-col w-full justify-center">
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-1.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-2.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-3.png" alt="asegurados" />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full justify-center mt-10">
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-4.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-5.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-8 w-auto" src="./Rectangle-6.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-16 w-auto" src="./Rectangle-7.png" alt="asegurados" />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full justify-center mt-10">
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-8.png" alt="asegurados" />
              </div>
              <div className="lg:w-3/12 flex justify-center items-center">
                <img className="lg:py-1 py-5 h-10 w-auto" src="./Rectangle-9.png" alt="asegurados" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
