export const validateStatus = (endDate: any, showCard: boolean) => {
    const map = new Map([['VIGENTE', "blue1"], ['PRÓXIMA A VENCER', "naranja1"], ['VENCIDA', "naranja1"]]);
    let hoy = new Date();
    let finVigencia = new Date(endDate);
    let difference = finVigencia.getTime() - hoy.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (showCard) {
        if (totalDays < 60 && totalDays > 0) {
            return (
                <div className={`${map.get('PRÓXIMA A VENCER')} flex items-center justify-center w-full text-center h-8`}>
                    <h1 className='text-white font-bold'> PRÓXIMA A VENCER </h1>
                </div>
            )
        } else if (totalDays >= 60) {
            return (
                <div className={`${map.get('VIGENTE')}  flex items-center justify-center w-full text-center h-8`}>
                    <h1 className='text-white font-bold'> VIGENTE </h1>
                </div>
            )
        }
        return (
            <div className={`${map.get('VENCIDA')} flex items-center justify-center w-full text-center h-8`}>
                <h1 className='text-white font-bold'> VENCIDA </h1>
            </div>
        )
    } else {
        if (totalDays < 60 && totalDays > 0) {
            return 'PRÓXIMA A VENCER'
        } else if (totalDays > 60) {
            return 'VIGENTE'
        }
        return 'VENCIDA'
    }

}